import { Container, Grid } from "@material-ui/core"
import { isNil } from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import type { PageProps } from "./Page"
import Page from "./Page"

const ContentGrid = styled(Grid)(
	(props) => css`
		padding-top: ${props.theme.spacing(3)}px;
		flex: 1 0 auto;
	`,
)

const MapContainer = styled.div`
	height: 400px;
	margin: 0px;
	padding: 0px;
`

export type PageWithMapProps = PageProps & {
	map: React.ReactNode
}

const PageWithMap: React.FC<PageWithMapProps> = ({
	map,
	children,
	title,
	...props
}) => {
	return (
		<Page title={title} {...props}>
			<ContentGrid container spacing={2}>
				<Grid item xs={12}>
					{!isNil(children) && <Container>{children}</Container>}
				</Grid>
				<Grid item xs={12}>
					<MapContainer>{map}</MapContainer>
				</Grid>
			</ContentGrid>
		</Page>
	)
}

export default PageWithMap
