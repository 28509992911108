import { Grid, Typography } from "@material-ui/core"
import React from "react"

export type ProfileProps = {
	rows: ProfileRow[]
}

export type ProfileRow = {
	label: string
	value: string | number
}

const Profile: React.FC<ProfileProps> = ({ rows }) => {
	return (
		<Grid container direction="column" spacing={2}>
			{rows.map((item) => (
				<Grid container item direction="column" spacing={1} key={item.label}>
					<Typography variant="h5">{item.label}</Typography>
					<Typography variant="body1">{item.value}</Typography>
				</Grid>
			))}
		</Grid>
	)
}

export default Profile
