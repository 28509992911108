import { CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import type { HiveNote, Measurements } from "@space-apps/beebox-api-client"
import type {
	NumberMeasurementChartDataType,
	RechartSeries,
} from "@space-apps/honeycomb"
import { NumberMeasurementChart } from "@space-apps/honeycomb"
import { isNumber } from "lodash"
import React from "react"
import type { UseQueryResult } from "react-query"
import { useOptionalSensorMeasurementsSeriesQuery } from "../hooks/useOptionalSensorMeasurementsSeriesQuery"
import { convertMeasurementsToSerie } from "../utils/chart"
import type { NormalizedRecording } from "./AnalyticsAndMediaContainer"

export type BeeAnalyticsIds = {
	detection?: number
	activity?: number
	sound?: number
}

type BeeAnalyticsChartsProps = {
	ids: BeeAnalyticsIds
	dateFrom: Date
	dateTo: Date
	recording?: NormalizedRecording
	selectClosestRecording: (date: Date) => void
	hiveNotes: HiveNote[]
}

type BeeAnalyticsSeriesQueryResult = UseQueryResult<Measurements<number>>

function useBeeAnalyticsSeries({
	ids,
	from,
	to,
}: {
	ids: BeeAnalyticsIds
	from: Date
	to: Date
}): {
	activity: BeeAnalyticsSeriesQueryResult
	detection: BeeAnalyticsSeriesQueryResult
	sound: BeeAnalyticsSeriesQueryResult
} {
	const sharedVariables = {
		from,
		to,
	}
	const sharedConfig = {
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	}
	const activity = useOptionalSensorMeasurementsSeriesQuery<number>(
		{
			...sharedVariables,
			deviceId: ids.activity,
			sensorKey: "bee_activity",
		},
		sharedConfig,
	)
	const detection = useOptionalSensorMeasurementsSeriesQuery<number>(
		{
			...sharedVariables,
			deviceId: ids.detection,
			sensorKey: "bee_count",
		},
		sharedConfig,
	)
	const sound = useOptionalSensorMeasurementsSeriesQuery<number>(
		{
			...sharedVariables,
			deviceId: ids.sound,
			sensorKey: "result",
		},
		sharedConfig,
	)
	return { activity, detection, sound }
}

const BeeAnalyticsCharts: React.FC<BeeAnalyticsChartsProps> = ({
	ids,
	dateFrom: from,
	dateTo: to,
	recording,
	selectClosestRecording,
}) => {
	const { activity, detection, sound } = useBeeAnalyticsSeries({
		ids,
		from,
		to,
	})
	const isLoading = [activity, detection, sound].some((r) => r.isLoading)

	if (isLoading) {
		return <CircularProgress />
	}

	if (
		[activity, detection, sound].every(
			({ data }) => data == null || data.length === 0,
		)
	) {
		return (
			<Alert severity="warning" variant="outlined">
				Nincs adat
			</Alert>
		)
	}

	const activitySerie = activity.data
		? convertMeasurementsToSerie("Hanganalitika", activity.data, {
				from,
				to,
		  })
		: undefined

	const detectionSerie = detection.data
		? convertMeasurementsToSerie("Méhek száma", detection.data, {
				from,
				to,
		  })
		: undefined

	const soundSerie = sound.data
		? convertMeasurementsToSerie("Hanganalitika", sound.data, {
				from,
				to,
		  })
		: undefined

	function renderChart(
		data: RechartSeries<NumberMeasurementChartDataType>,
		unit = "",
	): React.ReactElement {
		return (
			<div style={{ height: 350 }}>
				<NumberMeasurementChart
					key={Math.random()}
					data={data}
					unit={unit}
					showMarker
					markedData={
						recording
							? { dateInMs: recording.date.getTime(), value: 0 }
							: undefined
					}
					onClick={(value: unknown) => {
						const d = value as NumberMeasurementChartDataType
						if (isNumber(d.dateInMs)) {
							return selectClosestRecording(new Date(d.dateInMs))
						}
						throw new Error(
							`x is expected to be a Date, found ${typeof d.dateInMs}`,
						)
					}}
				/>
			</div>
		)
	}

	return (
		<>
			{activitySerie && renderChart([activitySerie])}
			{detectionSerie && renderChart([detectionSerie], "db")}
			{soundSerie && renderChart([soundSerie], "Hz")}
		</>
	)
}

export default BeeAnalyticsCharts
