import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import type { ListItemProps } from "@material-ui/core/ListItem"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import PictureIcon from "@material-ui/icons/Image"
import VideoIcon from "@material-ui/icons/Videocam"
import RecIcon from "@material-ui/icons/VolumeUp"
import groupBy from "lodash/groupBy"
import React, { Fragment, useMemo } from "react"
import styled, { css } from "styled-components"
import format from "../utils/format"
import type { CameraSensorKey } from "./Camera"

interface Recording {
	sensor: CameraSensorKey
	date: Date
	value: string
}

const ICON_MAP: Record<string, React.ReactElement> = {
	rec: <RecIcon />,
	video: <VideoIcon />,
	picture: <PictureIcon />,
}

type StyledListItemProps = ListItemProps & {
	odd: boolean
}

const MyListItem: React.FC<StyledListItemProps> = ({ children }) => (
	<ListItem>{children}</ListItem>
)

const StyledListItem = styled(MyListItem)(
	(props) => css`
		${props.odd ? "backgroundcolor: #f4f4f4f4;" : ""}
	`,
)

const CameraRecordingList: React.FC<{
	loading: boolean
	recordings: Recording[]
	onClick: (recording: Recording) => void
}> = ({ loading, recordings, onClick }) => {
	const recordingsByDate = useMemo(
		() => groupBy(recordings, (r) => r.date.toISOString()),
		[recordings],
	)
	if (!loading && recordings.length === 0) {
		return <Typography>Nincs találat.</Typography>
	}
	return (
		<List>
			{Object.entries(recordingsByDate).map(([date, recordingGroup], i) => (
				<Fragment key={date}>
					<StyledListItem odd={i % 2 === 1}>
						<Grid container alignItems="center">
							<Grid item xs={6}>
								{format.time(new Date(date))}
							</Grid>
							<Grid item container xs={6} justify="space-between" md={6}>
								{recordingGroup.map((recording) => (
									// If no xs value given, the auto computed value
									// breaks the List height
									<Grid key={recording.sensor} item xs={4}>
										<IconButton
											onClick={() => onClick(recording)}
											color="primary"
											size="small"
										>
											{ICON_MAP[recording.sensor]}
										</IconButton>
									</Grid>
								))}
							</Grid>
						</Grid>
					</StyledListItem>
					<Divider />
				</Fragment>
			))}
		</List>
	)
}

export default CameraRecordingList
