import { Grid, IconButton, Typography } from "@material-ui/core"
import {
	ArrowLeft,
	ArrowRight,
	FastForward,
	FastRewind,
} from "@material-ui/icons"
import React from "react"
import styled, { css } from "styled-components"
import format from "../utils/format"
import type {
	NormalizedRecording,
	NormalizedRecordings,
} from "./AnalyticsAndMediaContainer"

// Media files given evey minute
// to jump forward and backward we use 15 min
const SKIP_MINUTES = 15

type MediaStepperProps = {
	recordings: NormalizedRecordings
	selectedRecording?: NormalizedRecording
	setSelectedRecording: (recording: NormalizedRecording) => void
}

const StyledContainer = styled(Grid)(
	(props) => css`
		background-color: ${props.theme.palette.grey[50]};
	`,
)

const MediaStepper: React.FC<MediaStepperProps> = ({
	recordings,
	selectedRecording,
	setSelectedRecording,
}) => {
	const idx = selectedRecording
		? recordings.keys.indexOf(selectedRecording.id)
		: 0

	return (
		<StyledContainer container justify="space-between" alignItems="center">
			<Grid item>
				<IconButton
					onClick={() => {
						if (idx < SKIP_MINUTES) {
							setSelectedRecording(recordings.dict[0])
						} else {
							const key = recordings.keys[idx - SKIP_MINUTES]
							setSelectedRecording(recordings.dict[key])
						}
					}}
					disabled={idx <= 0}
				>
					<FastRewind fontSize="large" />
				</IconButton>

				<IconButton
					onClick={() => {
						const key = recordings.keys[idx - 1]
						setSelectedRecording(recordings.dict[key])
					}}
					disabled={idx <= 0}
				>
					<ArrowLeft fontSize="large" />
				</IconButton>
			</Grid>

			<Grid item>
				{selectedRecording && (
					<Typography>
						{format.dateTime(new Date(selectedRecording.date))}
					</Typography>
				)}
			</Grid>

			<Grid item>
				<IconButton
					onClick={() => {
						const key = recordings.keys[idx + 1]
						setSelectedRecording(recordings.dict[key])
					}}
					disabled={idx >= recordings.keys.length - 1}
				>
					<ArrowRight fontSize="large" />
				</IconButton>
				<IconButton
					onClick={() => {
						if (idx + SKIP_MINUTES <= recordings.keys.length - 1) {
							const key = recordings.keys[idx + SKIP_MINUTES]
							setSelectedRecording(recordings.dict[key])
						} else {
							setSelectedRecording(recordings.dict[recordings.keys.length - 1])
						}
					}}
					disabled={idx >= recordings.keys.length - 1}
				>
					<FastForward fontSize="large" />
				</IconButton>
			</Grid>
		</StyledContainer>
	)
}

export default MediaStepper
