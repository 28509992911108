import { Grid } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import HiveGroupListMap from "../components/HiveGroupListMap"
import Page from "./Page"

const MapContainer = styled(Grid)`
	flex: 1;
`

const StyledContainer = styled.div`
	height: 100%;
	margin: 0px;
	padding: 0px;
`

const MapPage: React.FC = () => {
	return (
		<Page title="Térkép" withoutFooter>
			<MapContainer container direction="column">
				<Grid item xs={12}>
					<StyledContainer>
						<HiveGroupListMap
							shouldFitToHives
							displayLegends
							scrollZoom
							dragPan
						/>
					</StyledContainer>
				</Grid>
			</MapContainer>
		</Page>
	)
}

export default MapPage
