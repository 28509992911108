import {
	Accordion,
	AccordionSummary,
	CircularProgress,
	Grid,
	Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import type {
	Hive,
	Measurement,
	SensorMeasurementsSeriesVariables,
} from "@space-apps/beebox-api-client"
import {
	useHiveNotesQuery,
	useMultipleSensorsMeasurementsSeries,
} from "@space-apps/beebox-api-client"
import { parseISO } from "date-fns"
import closestIndexTo from "date-fns/closestIndexTo"
import { groupBy, isEmpty, isNil } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled, { css } from "styled-components"
import { useIntervalContext } from "../context"
import { CAMERA_SENSOR_KEYS } from "../utils/sensor"
import BeeAnalyticsCharts from "./BeeAnalyticsCharts"
import MediaPlayer from "./MediaPlayer"
import MediaStepper from "./MediaStepper"

type AnalyticsAndMediaContainerProps = {
	hive: Hive
}

export type NormalizedRecording = {
	id: number
	date: Date
	pic?: Recording
	video?: Recording
	rec?: Recording
}

export type NormalizedRecordings = {
	dict: Record<number, NormalizedRecording>
	keys: number[]
}

export type CameraSensorKey = "rec" | "picture" | "video"

interface Recording {
	sensor: CameraSensorKey
	date: Date
	value: string
}

const StyledGrid = styled(Grid)(
	(props) => css`
		padding: ${props.theme.spacing(2)}px;
	`,
)

function normalizeRecordings(recordings: Recording[]): NormalizedRecordings {
	const recordingsByDate = groupBy(recordings, (r) => r.date.toISOString())
	const grouped = recordingsByDate as Record<string, Recording[]>
	const normalized = Object.entries(grouped).reduce<NormalizedRecordings>(
		(acc, [date, item], index) => {
			acc.dict[index] = {
				id: index,
				date: parseISO(date),
				pic: item.find((it) => it.sensor === "picture"),
				video: item.find((it) => it.sensor === "video"),
				rec: item.find((it) => it.sensor === "rec"),
			}
			acc.keys.push(index)
			return acc
		},
		{ dict: {}, keys: [] },
	)
	return normalized
}

const AnalyticsAndMediaContainer: React.FC<AnalyticsAndMediaContainerProps> = ({
	hive,
}) => {
	const { data: hiveNotes = [] } = useHiveNotesQuery({ hiveId: hive.id })

	const [interval] = useIntervalContext()
	const device = useMemo(
		() =>
			hive.devices.find(({ sensors }) =>
				sensors.some((sensor) => /(picture|rec|video)/.exec(sensor)),
			),
		[hive],
	)
	const [paramsChanged, setParamsChanged] = useState<boolean>(false)

	const beeAnalytics = useMemo(() => {
		if (device) {
			const {
				bee_detection: detection,
				bee_activity_device_id: activity,
				bee_sound_analytics_device_id: sound,
			} = device.properties
			return {
				detection,
				activity,
				sound,
			}
		}
		return {}
	}, [device])
	const [recordings, setRecordings] = useState<NormalizedRecordings>({
		dict: {},
		keys: [],
	})
	const [selectedRecording, setSelectedRecording] = useState<
		NormalizedRecording | undefined
	>()
	const selectClosestRecording = useCallback(
		(date: Date) => {
			const closestIndex = closestIndexTo(
				date,
				recordings.keys.map((key) => recordings.dict[key].date),
			)
			const closestRecording = recordings.dict[recordings.keys[closestIndex]]
			setSelectedRecording(closestRecording)
		},
		[recordings],
	)

	const availableSensors = useMemo(
		() =>
			hive.sensors
				.map((sensor) => sensor.key)
				.filter((sensor) => CAMERA_SENSOR_KEYS.includes(sensor)),
		[hive],
	) as CameraSensorKey[]

	const sensorParams: SensorMeasurementsSeriesVariables[] = useMemo(() => {
		return device
			? availableSensors.map((sensor) => ({
					deviceId: device.id,
					sensorKey: sensor,
					from: interval.from,
					to: interval.to,
			  }))
			: []
	}, [interval, availableSensors, device])

	const { data, isLoading } = useMultipleSensorsMeasurementsSeries(sensorParams)

	const dateToString = interval.to.toString()
	const dateFromString = interval.from.toString()
	useEffect(() => {
		setParamsChanged(true)
	}, [dateToString, dateFromString, device?.id])

	useEffect(() => {
		if (!isNil(data) && paramsChanged) {
			const recordData = data.reduce<Recording[]>((acc, curr, i) => {
				const ms = curr as Array<Measurement<string>>
				ms.forEach(({ date, value }) => {
					acc.push({ sensor: availableSensors[i], value, date })
				})
				return acc
			}, [])
			const normalized = normalizeRecordings(recordData)
			setRecordings(normalized)

			if (normalized.keys.length > 0) {
				setSelectedRecording(normalized.dict[normalized.keys[0]])
			}
			setParamsChanged(false)
		}
	}, [data, availableSensors, paramsChanged])

	return (
		<Accordion disabled={isEmpty(availableSensors)} variant="outlined">
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h5">Felvételek</Typography>
			</AccordionSummary>
			<StyledGrid container direction="column" spacing={1}>
				<Grid item xs={12}>
					<BeeAnalyticsCharts
						ids={beeAnalytics}
						dateFrom={interval.from}
						dateTo={interval.to}
						recording={selectedRecording}
						selectClosestRecording={selectClosestRecording}
						hiveNotes={hiveNotes}
					/>
				</Grid>
				{isLoading ? (
					<CircularProgress />
				) : (
					<>
						<Grid item>
							<MediaStepper
								recordings={recordings}
								setSelectedRecording={setSelectedRecording}
								selectedRecording={selectedRecording}
							/>
						</Grid>
						<Grid item>
							<MediaPlayer recording={selectedRecording} />
						</Grid>
					</>
				)}
			</StyledGrid>
		</Accordion>
	)
}

export default AnalyticsAndMediaContainer
