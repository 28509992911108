function sum(numbers: number[]): number {
	return numbers.reduce((acc, number) => acc + number, 0)
}

function avg(numbers: number[]): number {
	return sum(numbers) / numbers.length
}

const math = {
	sum,
	avg,
}

export default math
