import {
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Typography,
} from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useHiveQuery } from "@space-apps/beebox-api-client"
import React from "react"
import type { RouteComponentProps } from "react-router-dom"
import WrapperLink from "../components/WrapperLink"
import PageWithContainer from "./PageWithContainer"

type MatchParams = {
	hiveId: string
}

export type DeviceDebugListPageProps = RouteComponentProps<MatchParams>

const DeviceDebugListPage: React.FC<DeviceDebugListPageProps> = ({ match }) => {
	const { isLoading, data: hive, error } = useHiveQuery({
		id: Number(match.params.hiveId),
	})

	if (isLoading) {
		return (
			<Grid container justify="center" alignItems="center">
				<CircularProgress />
			</Grid>
		)
	}

	if (error != null || hive == null) {
		return (
			<PageWithContainer>
				<Grid container justify="center" alignItems="center">
					{error != null && (
						<Alert severity="error" variant="outlined">
							{(error as Error).message}
						</Alert>
					)}
					{hive == null && (
						<Alert severity="error" variant="outlined">
							&quot;{match.params.hiveId}&quot; azonosítójú kaptár nem létezik!
						</Alert>
					)}
				</Grid>
			</PageWithContainer>
		)
	}

	return (
		<PageWithContainer title={`Kaptár ${hive.name} eszközei`}>
			<Grid container direction="column" alignItems="center" spacing={3}>
				{hive.devices.map((item) => (
					<Grid item xs={12} sm={6} md={4} key={item.id}>
						<WrapperLink to={`/debug/device/${item.id}`}>
							<Card variant="outlined">
								<CardContent>
									<Typography variant="h5">Name: {item.name}</Typography>
									<Typography variant="h6">ID: {item.id}</Typography>
								</CardContent>
							</Card>
						</WrapperLink>
					</Grid>
				))}
			</Grid>
		</PageWithContainer>
	)
}

export default DeviceDebugListPage
