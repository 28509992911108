import React from "react"
import * as yup from "yup"
import { useCreateHiveInspectionSheetEntryMutation } from "../hooks/hiveInspectionSheet/useCreateHiveInspectionSheetEntryMutation"
import { useUpdateHiveInspectionSheetEntryMutation } from "../hooks/hiveInspectionSheet/useUpdateHiveInspectionSheetEntryMutation"
import type { HiveInspectionSheetEntry } from "../utils/hiveInspectionSheet"
import FormDialog from "./FormDialog"
import { HiveInspectionSheetFields } from "./HiveInspectionSheetFields"

export type HiveInspectionSheetFormDialogProps = {
	hiveId: number
	queenId: string
	open: boolean
	onClose: () => void
	initialValues?: HiveInspectionSheetEntry
}

const validationSchema = yup.object().shape({
	date: yup.date(), // TODO: Replace with date field
	population: yup.number().min(1).max(20).required(),
	comb: yup.number().min(3).max(20).required(),
	open: yup.number().min(1).max(20).required(),
	closed: yup.number().min(1).max(10).required(),
	food: yup.number().required(),
	ab: yup.number().oneOf([1, 2, 3, 4]).required(),
	calm: yup.number().oneOf([1, 2, 3, 4, 5]).required(),
	stay: yup.number().oneOf([1, 2, 3]).required(),
	clean: yup.number().oneOf([1, 2, 3]).required(),
	swarm: yup.number().oneOf([1, 2, 3, 4, 5]).required(),
	empty: yup.number().min(1).max(20).required(),
	fakecomb: yup.number().min(0).max(10).required(),
	bee: yup.number().min(-20).max(20).required(),
	brood: yup.number().min(-20).max(20).required(),
	honey: yup.number().min(-20).max(20).required(),
	sugar: yup.number().required(),
	size: yup.number().required(),
	type: yup.string().oneOf(["Rakodó", "Fekvő"]).required(),
	comment: yup.string(),
})

const defaultInitialValues: Omit<HiveInspectionSheetEntry, "id" | "queenId"> = {
	date: new Date(),
	population: 1,
	comb: 3,
	open: 1,
	closed: 1,
	food: 0,
	ab: 1,
	calm: 1,
	stay: 1,
	clean: 1,
	swarm: 1,
	empty: 1,
	fakecomb: 1,
	bee: 0,
	brood: 0,
	honey: 0,
	sugar: 0,
	type: "Fekvő",
	size: "0",
	comment: "",
}

export const HiveInspectionSheetFormDialog: React.FC<HiveInspectionSheetFormDialogProps> = ({
	hiveId,
	queenId,
	open,
	onClose,
	initialValues,
}) => {
	const createEntryMutation = useCreateHiveInspectionSheetEntryMutation({
		hiveId,
	})
	const updateEntryMutation = useUpdateHiveInspectionSheetEntryMutation({
		hiveId,
	})

	if (initialValues) {
		return (
			<FormDialog
				formik={{
					initialValues,
					onSubmit: async (values) => {
						await updateEntryMutation.mutateAsync({
							entry: values,
						})
					},
					validateOnChange: true,
					validationSchema,
					isInitialValid: false,
					fields: <HiveInspectionSheetFields />,
				}}
				mutationDialogProps={{
					result: updateEntryMutation,
					dialog: {
						open,
						onClose,
						components: {
							title: "Kaptárlap bejegyzés szerkesztése",
						},
					},
				}}
			/>
		)
	}
	return (
		<FormDialog
			formik={{
				initialValues: defaultInitialValues,
				onSubmit: async (values) => {
					await createEntryMutation.mutateAsync({
						entry: { ...values, queenId },
					})
				},
				validateOnChange: true,
				validationSchema,
				fields: <HiveInspectionSheetFields />,
			}}
			mutationDialogProps={{
				result: createEntryMutation,
				dialog: {
					open,
					onClose,
					components: {
						title: "Kaptárlap bejegyzés létrehozása",
					},
				},
			}}
		/>
	)
}
