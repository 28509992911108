import { CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import type { Hive, HiveGroup } from "@space-apps/beebox-api-client"
import { useMultipleSensorsMeasurementsSeries } from "@space-apps/beebox-api-client"
import { MassChart } from "@space-apps/honeycomb"
import { isEmpty } from "lodash"
import React, { useMemo } from "react"
import styled from "styled-components"
import { useIntervalContext } from "../context"
import { convertMeasurementsToSerie } from "../utils/chart"

const ChartDiv = styled.div`
	height: 20rem;
`

type HiveGroupListChartProps = {
	hiveGroups: HiveGroup[]
	hives: Hive[]
}

const HiveGroupListChart: React.FC<HiveGroupListChartProps> = ({
	hiveGroups,
	hives,
}) => {
	const [interval] = useIntervalContext()

	const weightSensors = hiveGroups.flatMap((hiveGroup) =>
		hiveGroup.sensors.filter((sensor) => sensor.key === "weight"),
	)
	// Every hive should have 1 device with 1 weight sensor, otherwise there will be duplicate sources
	const sources = weightSensors.map((sensor) => ({
		...sensor,
		sensorKey: sensor.key,
		from: interval.from,
		to: interval.to,
	}))

	const { isLoading, data, error } = useMultipleSensorsMeasurementsSeries<
		number
	>(sources)

	const hiveMap = useMemo(() => new Map(hives.map((hive) => [hive.id, hive])), [
		hives,
	])

	const massChartData = useMemo(() => {
		if (data) {
			return data.map((measurements, i) =>
				convertMeasurementsToSerie(
					hiveMap.get(sources[i].hiveId)?.name ??
						`${sources[i].hiveId}. ID-jű kaptár`,
					measurements,
				),
			)
		}
		return []
	}, [data, sources, hiveMap])

	if (isLoading) {
		return <CircularProgress />
	}
	if (error != null) {
		return (
			<Alert severity="error" variant="outlined">
				{(error as Error).message}
			</Alert>
		)
	}

	if (!data) {
		return (
			<Alert severity="error" variant="outlined">
				Nincs elég adat
			</Alert>
		)
	}
	return (
		<ChartDiv>
			<MassChart
				key={Math.random()}
				data={massChartData}
				displayLegends={!isEmpty(massChartData)}
			/>
		</ChartDiv>
	)
}

export default HiveGroupListChart
