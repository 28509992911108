import { Container, Grid } from "@material-ui/core"
import { isNil } from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import type { PageProps } from "./Page"
import Page from "./Page"

const ContentGrid = styled(Grid)(
	(props) => css`
		padding-top: ${props.theme.spacing(3)}px;
		padding-bottom: ${props.theme.spacing(3)}px;
		flex: 1 0 auto;
	`,
)

const PageWithContainer: React.FC<PageProps> = ({
	children,
	title,
	...props
}) => {
	return (
		<Page title={title} {...props}>
			<ContentGrid container>
				{!isNil(children) && <Container>{children}</Container>}
			</ContentGrid>
		</Page>
	)
}

export default PageWithContainer
