import type { Hive } from "@space-apps/beebox-api-client"
import React, { useCallback } from "react"
import MapContainer from "./MapContainer"

export type HiveMapProps = {
	id: number
	fixInitialBound?: boolean
}

const HiveMap: React.FC<HiveMapProps> = ({ id, fixInitialBound }) => {
	const filterByHiveId = useCallback((hive: Hive) => hive.id === id, [id])
	return (
		<MapContainer
			predicate={filterByHiveId}
			fixInitialBound={fixInitialBound}
		/>
	)
}

export default HiveMap
