import Button from "@material-ui/core/Button"
import type { FieldProps } from "formik"
import React, { useCallback } from "react"
import type { DropzoneOptions } from "react-dropzone"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"
import DropzoneThumbnail from "./DropzoneThumbnail"

export type DropzoneFieldProps<FormValues> = FieldProps<
	File[] | null,
	FormValues
> &
	DropzoneOptions

export const StyledThumbnailsContainer = styled.aside`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 16px;
`

export function getFileId(file: File): string {
	return file.name + file.size.toString() + file.type
}

function DropzoneField<FormValues>({
	field,
	form,
	...props
}: DropzoneFieldProps<FormValues>): React.ReactElement {
	const handleDrop = useCallback<Required<DropzoneOptions>["onDrop"]>(
		(acceptedFiles) => {
			form.setFieldValue(field.name, acceptedFiles)
		},
		[field.name, form],
	)
	const { getRootProps, getInputProps } = useDropzone({
		...props,
		onDrop: handleDrop,
	})

	const createFileRemoveHandler = useCallback(
		(fileToRemove: File) => () => {
			form.setFieldValue(
				field.name,
				(field.value ?? []).filter(
					(file) => getFileId(file) !== getFileId(fileToRemove),
				),
			)
		},
		[field.name, field.value, form],
	)

	return (
		<div>
			<input {...getInputProps()} />
			<Button {...getRootProps()} variant="outlined" color="primary">
				Tallózás
			</Button>
			<StyledThumbnailsContainer>
				{(field.value ?? []).map((file) => (
					<DropzoneThumbnail
						key={getFileId(file)}
						onRemove={createFileRemoveHandler(file)}
						// Caution: URL API not supported in IE, see: https://caniuse.com/?search=url
						src={URL.createObjectURL(file)}
						alt={file.name}
					/>
				))}
			</StyledThumbnailsContainer>
		</div>
	)
}

export default DropzoneField
