import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { BackIconButton } from "@space-apps/honeycomb"
import React from "react"

type BeeBoxAppBarProps = {
	title?: string
	input?: React.ReactElement
	onMenuClick: () => void
	onBackIconClick: () => void
}

const BeeBoxAppBar: React.FC<BeeBoxAppBarProps> = ({
	title,
	input,
	onMenuClick,
	onBackIconClick,
}) => {
	return (
		<AppBar position="sticky">
			<Toolbar>
				<IconButton
					edge="start"
					color="inherit"
					aria-label="menu"
					onClick={onMenuClick}
				>
					<MenuIcon />
				</IconButton>
				<BackIconButton color="inherit" onClick={onBackIconClick} />
				<Typography variant="h6" style={{ flexGrow: 1 }}>
					{title}
				</Typography>
				<div>{input}</div>
			</Toolbar>
		</AppBar>
	)
}

export default BeeBoxAppBar
