import React from "react"
import type { RouteProps } from "react-router-dom"
import { Redirect, Route } from "react-router-dom"
import { useMe } from "../hooks/useMe"

type Props = RouteProps & {
	guard?: boolean
}

const ProtectedRoute: React.FC<Props> = ({
	guard = false,
	children,
	...rest
}) => {
	const { data: user } = useMe() // Should access current user from cache
	if (guard) {
		return user ? <Route {...rest}>{children}</Route> : <Redirect to="/login" />
	}
	return user ? <Redirect to="/" /> : <Route {...rest}>{children}</Route>
}

export default ProtectedRoute
