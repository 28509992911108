/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { parseISO } from "date-fns"
import { enableMapSet } from "immer"
import type { MergeExclusive } from "type-fest"
import { v4 as uuid } from "uuid"

enableMapSet()

const VERSION = 2

export type HiveInspectionSheetEntry = {
	id: string
	queenId: string

	/** Dátum */
	date?: Date // Date

	/** Nép */
	population: number
	/** Lép */
	comb: number
	/** Nyitott */
	open: number
	/** Fedett */
	closed: number
	/** Fias */
	brood: number
	/** Élelem (kg) */
	food: number
	/** AB */
	ab: 1 | 2 | 3 | 4
	/** Nyugodt */
	calm: 1 | 2 | 3 | 4 | 5
	/** Lépen maradás */
	stay: 1 | 2 | 3
	/** Tisztogató hajlam */
	clean: 1 | 2 | 3
	/** Rajzási hajlam */
	swarm: 1 | 2 | 3 | 4 | 5
	/** Üres */
	empty: number
	/** Műlép */
	fakecomb: number
	/** Méh */
	bee: number
	/** Még egy fias */
	// fias2: number
	/** Méz */
	honey: number
	/** Cukor (kg) */
	sugar: number
	/** Kaptár típusa */
	type: "Rakodó" | "Fekvő"
	/** Keretméret */
	size?: string // TODO select options
	/** Megjegyzés */
	comment?: string
}

export type HiveInspectionSheetQueen = {
	id: string

	/** Törzskönyvi száma */
	number: string
	/** Jele */
	mark: string

	/** Anyacsere dátuma */
	swapDate: Date
	/** Anyacsere oka */
	swapReason?: string

	/** Morfológia - Szín */
	morphologyColor?: string
	/** Morfológia - Szipóka */
	morphologySucc?: string
}

export type PlainHiveInspectionSheet = {
	hiveId: number
	queenId: string
	queens: Map<string, HiveInspectionSheetQueen>
	entries: Map<string, HiveInspectionSheetEntry>
	__version: number | string
}
export type HiveInspectionSheet = PlainHiveInspectionSheet & {
	/** populated queen property */
	queen: HiveInspectionSheetQueen
}

export type PlainHiveInspectionSheetArgs = { phis: PlainHiveInspectionSheet }

function replacer(this: any, key: string, value: any): any {
	const originalObject = this[key]
	if (originalObject instanceof Map) {
		return {
			dataType: "Map",
			value: [...originalObject],
		}
	}
	if (originalObject instanceof Date) {
		return {
			dataType: "Date",
			value: originalObject.toISOString(),
		}
	}
	return value
}

function reviver(key: string, value: any): any {
	if (typeof value === "object" && value !== null) {
		if (value.dataType === "Map") {
			return new Map(value.value)
		}
		if (value.dataType === "Date") {
			return parseISO(value.value)
		}
	}
	return value
}

export function toString(phis: PlainHiveInspectionSheet): string {
	return JSON.stringify({ value: JSON.stringify(phis, replacer) })
}

export function toPlainHiveInspectionSheet({
	value,
}: {
	value: string
}): MergeExclusive<{ phis: PlainHiveInspectionSheet }, { error: Error }> {
	const phis = JSON.parse(value, reviver) as PlainHiveInspectionSheet
	if (VERSION !== phis.__version) {
		return {
			error: new Error(
				`Local and remote Hive Inspection Sheet versions are different: local "${VERSION}", remote "${phis.__version}"`,
			),
		}
	}
	return { phis }
}

export function getHiveInspectionSheet({
	phis,
}: PlainHiveInspectionSheetArgs): HiveInspectionSheet {
	const queen = phis.queens.get(phis.queenId)
	if (!queen) {
		throw new Error("no queen found")
	}
	return {
		...phis,
		queen,
	}
}

export function createQueen({
	queen,
	hiveId,
}: {
	hiveId: number
	queen: Omit<HiveInspectionSheetQueen, "id">
}): HiveInspectionSheet {
	const newQueen = {
		...queen,
		id: uuid(),
	}
	return getHiveInspectionSheet({
		phis: {
			hiveId,
			queens: new Map<string, HiveInspectionSheetQueen>().set(
				newQueen.id,
				newQueen,
			),
			queenId: newQueen.id,
			entries: new Map<string, HiveInspectionSheetEntry>(),
			__version: VERSION,
		},
	})
}

export function updateQueen({
	queen,
	phis,
}: {
	queen: RequiredBy<Partial<HiveInspectionSheetQueen>, "id">
} & PlainHiveInspectionSheetArgs): HiveInspectionSheet {
	const currentQueen = phis.queens.get(phis.queenId)
	if (!currentQueen) {
		throw new Error("no queen found")
	}
	const updatedQueen = {
		...currentQueen,
		...queen,
	}
	phis.queens.set(updatedQueen.id, updatedQueen)
	return getHiveInspectionSheet({ phis })
}

export const swapQueen = ({
	phis,
	queen,
}: {
	queen: Omit<HiveInspectionSheetQueen, "id">
} & PlainHiveInspectionSheetArgs): HiveInspectionSheet => {
	const newQueen = {
		...queen,
		id: uuid(),
	}
	return getHiveInspectionSheet({
		phis: {
			...phis,
			queens: phis.queens.set(newQueen.id, newQueen),
			queenId: newQueen.id,
		},
	})
}

export function createHiveInspectionSheetEntry({
	phis,
	entry,
}: {
	entry: Omit<HiveInspectionSheetEntry, "id">
} & PlainHiveInspectionSheetArgs): HiveInspectionSheet {
	const hiveInspectionSheetEntry = { ...entry, id: uuid() }
	phis.entries.set(hiveInspectionSheetEntry.id, hiveInspectionSheetEntry)
	return getHiveInspectionSheet({ phis })
}

export function getHiveInspectionSheetEntry({
	phis,
	entryId,
}: {
	entryId: string
} & PlainHiveInspectionSheetArgs): HiveInspectionSheetEntry | undefined {
	return phis.entries.get(entryId)
}

export function updateHiveInspectionSheetEntry({
	phis,
	entry,
}: {
	entry: RequiredBy<Partial<HiveInspectionSheetEntry>, "id" | "queenId">
} & PlainHiveInspectionSheetArgs): HiveInspectionSheet {
	const currentEntry = phis.entries.get(entry.id)
	if (!currentEntry) {
		throw new Error("no hive inspection sheet entry found")
	}
	const newEntry = { ...currentEntry, ...entry }
	phis.entries.set(newEntry.id, newEntry)
	return getHiveInspectionSheet({ phis })
}

export function deleteHiveInspectionSheetEntry({
	phis,
	entryId,
}: {
	entryId: string
} & PlainHiveInspectionSheetArgs): HiveInspectionSheet {
	const currentEntry = phis.entries.get(entryId)
	if (!currentEntry) {
		throw new Error("no hive inspection sheet entry found")
	}
	phis.entries.delete(entryId)
	return getHiveInspectionSheet({ phis })
}
