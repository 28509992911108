import IconButton from "@material-ui/core/IconButton"
import CancelIcon from "@material-ui/icons/Cancel"
import React from "react"
import styled from "styled-components"

const StyledThumbnail = styled.div`
	position: relative;
	display: inline-flex;
	border-radius: 2px;
	border: 1px solid #eaeaea;
	margin-bottom: 8px;
	margin-right: 8px;
	width: 100px;
	height: 100px;
	padding: 4px;
	box-sizing: border-box;
`

const StyledThumbnailInner = styled.div`
	display: flex;
	min-width: 0;
	overflow: hidden;
`

const StyledThumbnailImage = styled.img`
	display: block;
	width: auto;
	height: 100%;
`

const StyledIconButton = styled(IconButton)`
	position: absolute;
	top: -12px;
	right: -12px;
`

export type DropzoneThumbnailProps = {
	onRemove: () => void
	src: string
	alt: string
}

const DropzoneThumbnail: React.FC<DropzoneThumbnailProps> = ({
	onRemove: handleRemove,
	src,
	alt,
}) => {
	return (
		<StyledThumbnail>
			<StyledIconButton onClick={handleRemove} size="small">
				<CancelIcon fontSize="inherit" color="error" />
			</StyledIconButton>
			<StyledThumbnailInner>
				<StyledThumbnailImage src={src} alt={alt} />
			</StyledThumbnailInner>
		</StyledThumbnail>
	)
}

export default DropzoneThumbnail
