/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {
	GetHiveInspectionSheetVariables,
	HiveInspectionSheet,
} from "@space-apps/beebox-api-client"
import type { UseQueryOptions } from "react-query"
import * as hiveInspectionSheet from "../../utils/hiveInspectionSheet"
import { useParsedHiveInspectionSheetQuery } from "./useParsedHiveInspectionSheetQuery"

export function useHiveInspectionSheetQuery(
	args: GetHiveInspectionSheetVariables,
	options?: UseQueryOptions<HiveInspectionSheet>,
) {
	const { data: phis, isLoading, error } = useParsedHiveInspectionSheetQuery(
		args,
		options,
	)
	if (isLoading || !phis) {
		return { isLoading, error }
	}
	return {
		data: hiveInspectionSheet.getHiveInspectionSheet({ phis }),
		isLoading,
		error,
	}
}
