import AppBar from "@material-ui/core/AppBar"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Slide from "@material-ui/core/Slide"
import Toolbar from "@material-ui/core/Toolbar"
import type { TransitionProps } from "@material-ui/core/transitions"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import type { Device, DeviceInHive, Hive } from "@space-apps/beebox-api-client"
import React from "react"
import styled from "styled-components"
import format from "../utils/format"

const DEVELOPMENT = process.env.NODE_ENV === "development"

const Transition = React.forwardRef(function Transition(
	props: TransitionProps,
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />
})

function extendSrc(src: string): string {
	if (DEVELOPMENT) {
		return `https://bee-box.io${src}`
	}
	return src
}

const ContainerDiv = styled.div`
	height: 100%;
	overflow: hidden;
`

const StyledImage = styled.img`
	object-fit: scale-down;
	width: 100%;
	max-height: "100%";
`

const StyledVideo = styled.video`
	width: 100%;
	height: 100%;
`

const CameraSensorDialog: React.FC<{
	open: boolean
	onClose: () => void
	hive: Hive
	device: Device | DeviceInHive
	recording?: {
		date: Date | string
		key: "rec" | "picture" | "video"
		src: string
	}
}> = ({ open, onClose, hive, device, recording }) => {
	if (!recording) {
		return null
	}
	return (
		<Dialog
			fullScreen
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
		>
			<AppBar
				style={{
					position: "relative",
				}}
			>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						onClick={onClose}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
					<Typography variant="h6">
						{hive.name} &gt; {device.name} &gt; {recording.key} &gt;{" "}
						{format.dateTime(new Date(recording.date))}
					</Typography>
				</Toolbar>
			</AppBar>

			{recording.key === "picture" && (
				<ContainerDiv>
					<StyledImage
						src={extendSrc(recording.src)}
						alt={`Kép ${format.dateTime(new Date(recording.date))}`}
					/>
				</ContainerDiv>
			)}

			{recording.key === "rec" && (
				// eslint-disable-next-line jsx-a11y/media-has-caption
				<audio controls>
					<source src={extendSrc(recording.src)} type="audio/mpeg" />
					<span>A böngésző nem támogatja a hang lejátszást.</span>
				</audio>
			)}

			{recording.key === "video" && (
				<ContainerDiv>
					{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
					<StyledVideo loop controls>
						<source src={extendSrc(recording.src)} type="video/mp4" />
						<span>A böngésző nem támogatja a videó lejátszást.</span>
					</StyledVideo>
				</ContainerDiv>
			)}
		</Dialog>
	)
}

export default CameraSensorDialog
