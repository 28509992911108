import { Card, CardContent, Grid, Typography } from "@material-ui/core"
import type { Device } from "@space-apps/beebox-api-client"
import React from "react"

interface DeviceInfoProps {
	device: Device
}

const DeviceInfo: React.FC<DeviceInfoProps> = ({ device }) => {
	return (
		<Card variant="outlined">
			<CardContent>
				<Grid container direction="column" spacing={2}>
					{Object.entries(device).map(([key, value]) => {
						if (key !== "sensors") {
							return (
								<Grid
									key={`${device.id}-${key}`}
									item
									container
									justify="space-between"
								>
									<Typography variant="h5">{key}</Typography>
									<Typography variant="h5">{JSON.stringify(value)}</Typography>
								</Grid>
							)
						}
						return null
					})}
				</Grid>
			</CardContent>
		</Card>
	)
}

export default DeviceInfo
