import { Button, CircularProgress, Grid, TextField } from "@material-ui/core"
import { useLoginMutation } from "@space-apps/beebox-api-client"
import React, { useCallback, useState } from "react"

const LoginForm: React.FC = () => {
	const { mutate: login, isLoading } = useLoginMutation()

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")

	const handleSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			login({ username: email, password })
		},
		[login, email, password],
	)

	return (
		<form onSubmit={handleSubmit}>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				spacing={2}
			>
				<Grid item>
					<TextField
						label="Email"
						type="email"
						variant="outlined"
						onChange={(e): void => setEmail(e.target.value)}
						value={email}
						disabled={isLoading}
					/>
				</Grid>

				<Grid item>
					<TextField
						label="Password"
						type="password"
						variant="outlined"
						onChange={(e): void => setPassword(e.target.value)}
						value={password}
						disabled={isLoading}
					/>
				</Grid>

				<Grid item>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						disabled={isLoading}
					>
						{isLoading && <CircularProgress />}
						Log in
					</Button>
				</Grid>
			</Grid>
		</form>
	)
}

export default LoginForm
