import React from "react"
import MapContainer from "./MapContainer"

type HiveGroupListMapProps = {
	fixInitialBound?: boolean
	displayLegends?: boolean
	defaultViewport?: { longitude: number; latitude: number; zoom: number }
	shouldFitToHives?: boolean
	dragPan?: boolean
	scrollZoom?: boolean
}

const HiveGroupListMap: React.FC<HiveGroupListMapProps> = ({
	fixInitialBound,
	displayLegends,
	defaultViewport,
	shouldFitToHives,
	dragPan,
	scrollZoom,
}) => {
	return (
		<MapContainer
			fixInitialBound={fixInitialBound}
			displayLegends={displayLegends}
			defaultViewport={defaultViewport}
			shouldFitToHives={shouldFitToHives}
			dragPan={dragPan}
			scrollZoom={scrollZoom}
		/>
	)
}

export default HiveGroupListMap
