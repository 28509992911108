import MarkerIcon from "@material-ui/icons/LocationOnTwoTone"
import { Alert } from "@material-ui/lab"
import { isNil } from "lodash"
import React, { useState } from "react"
import type { ViewportProps } from "react-map-gl"
import InteractiveMap, { Layer, Marker, Source } from "react-map-gl"

interface SensorDebugMapProps {
	point?: GeoJSON.Point
}

const osm = {
	source: {
		id: "osm",
		type: "raster",
		tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
		tileSize: 256,
	},
	layer: {
		id: "osm",
		type: "raster",
		source: "osm",
		paint: {},
		layout: {},
	},
}

const EMPTY_STYLE = {
	version: 8,
	sources: {},
	layers: [],
}

const SensorDebugMap: React.FC<SensorDebugMapProps> = ({ point }) => {
	const [viewport, setViewport] = useState<Partial<ViewportProps>>()

	const handleViewportChange = (nextViewport: ViewportProps): void => {
		setViewport(nextViewport)
	}
	if (isNil(point)) {
		return (
			<Alert severity="error" variant="outlined">
				Nem található aktuális érték!
			</Alert>
		)
	}
	return (
		<div style={{ height: 400 }}>
			<InteractiveMap
				mapStyle={EMPTY_STYLE}
				longitude={point.coordinates[0]}
				latitude={point.coordinates[1]}
				zoom={8.9}
				{...viewport}
				width="100%"
				height="100%"
				onViewportChange={handleViewportChange}
			>
				<Source {...osm.source}>
					<Layer {...osm.layer} />
				</Source>

				<Marker
					latitude={point.coordinates[1]}
					longitude={point.coordinates[0]}
				>
					<MarkerIcon color="primary" />
				</Marker>
			</InteractiveMap>
		</div>
	)
}

export default SensorDebugMap
