import type { User } from "@space-apps/beebox-api-client"
import { useMeQuery } from "@space-apps/beebox-api-client"
import type { UseQueryResult } from "react-query"

export function useMe(): UseQueryResult<User> {
	return useMeQuery({
		retry: false,
		retryOnMount: false,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		staleTime: Infinity,
	})
}
