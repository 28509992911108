import {
	AppBar,
	Dialog,
	DialogContent,
	IconButton,
	Toolbar,
	Typography,
} from "@material-ui/core"
import type { ColDef } from "@material-ui/data-grid"
import { DataGrid } from "@material-ui/data-grid"
import { Close } from "@material-ui/icons"
import EditIcon from "@material-ui/icons/Edit"
import React, { useCallback, useState } from "react"
import styled from "styled-components"
import format from "../utils/format"
import type {
	HiveInspectionSheet,
	HiveInspectionSheetQueen,
} from "../utils/hiveInspectionSheet"
import { HiveQueenFormDialog } from "./HiveQueenFormDialog"

const columns: ColDef[] = [
	{ field: "number", headerName: "Törzskönyvi szám", width: 200 },
	{ field: "mark", headerName: "Jele", width: 200 },
	{
		field: "swapReason",
		headerName: "Csere oka",
		width: 200,
	},
	{ field: "morphologyColor", headerName: "Szín", width: 100 },
	{ field: "morphologySucc", headerName: "Szipóka", width: 100 },
]

const StyledTitle = styled(Typography)`
	flex-grow: 1;
`

const StyledAppBar = styled(AppBar)`
	position: relative;
`

const CloseIcon = styled(Close)`
	color: white;
`

interface HiveQueensTableProps {
	hiveInspectionSheet: HiveInspectionSheet
	open: boolean
	onClose: () => void
}

const HiveQueensTable: React.FC<HiveQueensTableProps> = ({
	hiveInspectionSheet,
	open,
	onClose,
}) => {
	const [
		hiveInspectionSheetQueenToEdit,
		setHiveInspectionSheetQueenToEdit,
	] = useState<HiveInspectionSheetQueen | undefined>()
	const renderActionsCell = useCallback<Required<ColDef>["renderCell"]>(
		({ value: id }) => (
			<div>
				<IconButton
					aria-label="edit"
					size="small"
					onClick={() =>
						setHiveInspectionSheetQueenToEdit(
							hiveInspectionSheet.queens.get(id as string),
						)
					}
				>
					<EditIcon fontSize="small" />
				</IconButton>
			</div>
		),
		[hiveInspectionSheet.queens],
	)

	return (
		<Dialog open={open} fullScreen onClose={onClose}>
			<StyledAppBar>
				<Toolbar>
					<StyledTitle variant="h6">Anyák</StyledTitle>
					<IconButton edge="end" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</StyledAppBar>
			<DialogContent>
				<div style={{ height: 400, width: "100%" }}>
					<DataGrid
						rows={Array.from(hiveInspectionSheet.queens.values())}
						columns={[
							{
								field: "id",
								headerName: "Műveletek",
								renderCell: renderActionsCell,
							},
							{
								field: "swapDate",
								headerName: "Csere Dátum",
								width: 200,
								renderCell: ({ value }) => {
									if (value instanceof Date) {
										return <div>{format.date(value)}</div>
									}
									return <div />
								},
							},
							...columns,
						]}
					/>
					{hiveInspectionSheetQueenToEdit && (
						<HiveQueenFormDialog
							hiveId={hiveInspectionSheet.hiveId}
							initialValues={hiveInspectionSheetQueenToEdit}
							open
							onClose={() => setHiveInspectionSheetQueenToEdit(undefined)}
						/>
					)}
				</div>
			</DialogContent>
		</Dialog>
	)
}

export default HiveQueensTable
