import type {
	Device,
	Hive,
	MeasurementValue,
	Sensor,
} from "@space-apps/beebox-api-client"
import type { Sensor as SensorData } from "@space-apps/honeycomb"
import { SensorStatus, SensorType } from "@space-apps/honeycomb"
import type { Dictionary } from "lodash"
import { groupBy } from "lodash"
import math from "./math"

export const CAMERA_SENSOR_KEYS = ["rec", "picture", "video"]

function extractValue<MV extends MeasurementValue>(
	sensor: Sensor<MV>,
): MV | undefined {
	return sensor.measurement?.value
}

function sum(sensors: Array<Sensor<number>>): number {
	return math.sum(
		sensors.map(extractValue).filter((value): value is number => value != null),
	)
}

function avg(sensors: Array<Sensor<number>>): number {
	return math.avg(
		sensors.map(extractValue).filter((value): value is number => value != null),
	)
}

function findOneByKey<MV extends MeasurementValue>(
	sensors: Array<Sensor<MV>>,
	sensorKey: string,
): Sensor<MV> | undefined {
	return sensors.find((item) => item.key === sensorKey)
}

/**
 * TODO: Replace this lookup tables when sensor keys are finalized by the hardware and backend team
 *
 * The problem is that `SensorTypeLookup["none"]` is of type `SensorType`
 */
const SensorTypeLookup: Record<string, SensorType> = {
	weight: SensorType.Scale,
	temp_load: SensorType.Temperature,
	humidity: SensorType.Humidity,
	camera: SensorType.Camera,
	mic: SensorType.Microphone,
}

/**
 * TODO: Replace this lookup tables when sensor keys are finalized by the hardware and backend team
 *
 * The problem is that `SensorStatusLookup["none"]` is of type `SensorStatus`
 */
const SensorStatusLookup: Record<string, SensorStatus> = {
	OK: SensorStatus.Ok,
	ERROR: SensorStatus.Error,
	WARN: SensorStatus.Warning,
}

function getStatus<MV extends MeasurementValue>(
	sensor: Sensor<MV>,
): SensorData {
	return {
		sensorType: SensorTypeLookup[sensor.key],
		key: sensor.key,
		status: SensorStatusLookup[sensor.status],
	}
}

function groupByHive<MV extends MeasurementValue>(
	sensors: Array<Sensor<MV>>,
): Dictionary<Array<Sensor<MV>>> {
	return groupBy(sensors, (sensor) => sensor.hiveId)
}

function hasCamera(device: Device | Hive): boolean {
	return device.sensors.some((sensor) =>
		CAMERA_SENSOR_KEYS.includes(sensor.key),
	)
}

const sensor = {
	extractValue,
	sum,
	avg,
	findOneByKey,
	SensorTypeLookup,
	SensorStatusLookup,
	getStatus,
	groupByHive,
	hasCamera,
}

export default sensor
