import type { FlagKey } from "@space-apps/honeycomb"
import type { FieldProps } from "formik"
import React, { useCallback } from "react"
import type { FlagInputValue } from "./FlagInput"
import FlagInput from "./FlagInput"

export type FlagFieldProps<FormValues> = FieldProps<
	FlagKey[] | null | undefined,
	FormValues
> & { multiple?: boolean }

function FlagField<FormValues>({
	field,
	form,
	multiple = false,
}: FlagFieldProps<FormValues>): React.ReactElement {
	const handleChange = useCallback(
		(value: FlagInputValue) => {
			form.setFieldValue(field.name, value)
		},
		[form, field.name],
	)
	return (
		<FlagInput
			onChange={handleChange}
			multiple={multiple}
			value={field.value}
		/>
	)
}

export default FlagField
