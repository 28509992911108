import Grid from "@material-ui/core/Grid"
import LinearProgress from "@material-ui/core/LinearProgress"
import type {
	Hive,
	Measurement,
	SensorMeasurementsSeriesVariables,
} from "@space-apps/beebox-api-client"
import { useMultipleSensorsMeasurementsSeries } from "@space-apps/beebox-api-client"
import { parse, startOfDay } from "date-fns"
import { isNil } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import format, { DATE_TIME_FORMAT } from "../utils/format"
import { CAMERA_SENSOR_KEYS } from "../utils/sensor"
import CameraRecordingList from "./CameraRecordingList"
import CameraSensorDialog from "./CameraSensorDialog"
import CameraSensorQueryInputGroup from "./CameraSensorQueryInputGroup"

export type CameraSensorKey = "rec" | "picture" | "video"

interface Recording {
	sensor: CameraSensorKey
	date: Date
	value: string
}

export type TimeOfDay =
	| "00:00-05:59"
	| "06:00-11:59"
	| "12:00-17:59"
	| "18:00-23:59"

export interface CameraSensorQueryInput {
	day: Date
	tod: TimeOfDay
}

const Camera: React.FC<{ hive: Hive }> = ({ hive }) => {
	const device = hive.devices[0]
	const [params, setParams] = useState<{
		from: Date
		to: Date
	}>()
	const availableSensors = useMemo(
		() =>
			hive.sensors
				.map((item) => item.key)
				.filter((s) => CAMERA_SENSOR_KEYS.includes(s)),
		[hive.sensors],
	) as CameraSensorKey[]

	const handleChange = useCallback((value: CameraSensorQueryInput) => {
		const day = format.date(startOfDay(value.day))
		const tod = value.tod.split("-")
		const from = parse(`${day} ${tod[0]}`, DATE_TIME_FORMAT, new Date())
		const to = parse(`${day} ${tod[1]}`, DATE_TIME_FORMAT, new Date())
		setParams({
			from,
			to,
		})
	}, [])

	// Transform params to sensor query params
	const sensorParams: SensorMeasurementsSeriesVariables[] = useMemo(() => {
		if (params) {
			return availableSensors.map((item) => ({
				deviceId: device.id,
				sensorKey: item.toString(),
				from: params.from,
				to: params.to,
			}))
		}
		return []
	}, [params, availableSensors, device.id])

	const { data, isLoading } = useMultipleSensorsMeasurementsSeries(sensorParams)

	// Transform query data to Recordings type
	const recordings = useMemo(() => {
		if (!isNil(data)) {
			return data.reduce<Recording[]>((acc, curr, i) => {
				const ms = curr as Array<Measurement<string>>
				ms.forEach(({ date, value }) => {
					acc.push({ sensor: availableSensors[i], value, date })
				})
				return acc
			}, [])
		}
		return []
	}, [data, availableSensors])

	const [dialogState, setDialogState] = useState<{
		recording?: Recording
		open: boolean
	}>({
		open: false,
	})
	const handleOpen = useCallback((recording: Recording) => {
		setDialogState({ open: true, recording })
	}, [])
	const handleClose = useCallback(() => {
		setDialogState({ open: false })
	}, [])

	return (
		<>
			<Grid container direction="column" justify="flex-start" spacing={1}>
				<Grid item>
					<Grid
						container
						direction="column"
						justify="flex-start"
						alignItems="stretch"
						spacing={1}
					>
						<Grid item>
							<CameraSensorQueryInputGroup
								onChange={handleChange}
								disabled={isLoading}
							/>
						</Grid>

						{isLoading && (
							<Grid item>
								<LinearProgress />
							</Grid>
						)}
					</Grid>
				</Grid>

				<Grid item>
					<CameraRecordingList
						loading={isLoading}
						recordings={recordings}
						onClick={handleOpen}
					/>
				</Grid>
			</Grid>

			<CameraSensorDialog
				open={dialogState.open}
				onClose={handleClose}
				hive={hive}
				device={device}
				recording={
					dialogState.recording && {
						date: dialogState.recording.date,
						key: dialogState.recording.sensor,
						src: dialogState.recording.value,
					}
				}
			/>
		</>
	)
}

export default Camera
