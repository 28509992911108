import type { GridProps } from "@material-ui/core"
import { Grid, Popover, TextField } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { endOfDay, endOfTomorrow, isSameDay, startOfDay } from "date-fns"
import type { DateRange } from "materialui-daterange-picker"
import { DateRangePicker } from "materialui-daterange-picker"
import React, { useCallback, useRef } from "react"
import { useToggle } from "../hooks/useToggle"
import format from "../utils/format"

type DateRangeInputProps = {
	value: DateRange
	onChange: (value: DateRange) => void
	gridProps?: GridProps
	className?: string
}

const CssTextField = withStyles(
	({
		palette: {
			primary: { contrastText },
		},
	}) => ({
		root: {
			"& label.Mui-focused": {
				color: contrastText,
			},
			"& label": {
				color: contrastText,
			},
			"& .MuiOutlinedInput-root": {
				color: contrastText,
				"& fieldset": {
					borderColor: contrastText,
				},
				"&:hover fieldset": {
					borderColor: contrastText,
				},
				"&.Mui-focused fieldset": {
					borderColor: contrastText,
				},
			},
			color: contrastText,
		},
	}),
)(TextField)

const DateRangeInput: React.FC<DateRangeInputProps> = ({
	value,
	onChange,
	className,
	gridProps,
}) => {
	const changeHandler = useCallback(
		(dateRange: DateRange) => {
			if (dateRange.startDate && dateRange.endDate) {
				if (isSameDay(dateRange.startDate, dateRange.endDate)) {
					onChange({
						startDate: startOfDay(dateRange.startDate),
						endDate: endOfDay(dateRange.endDate),
					})
				} else {
					onChange({
						startDate: dateRange.startDate,
						endDate: endOfDay(dateRange.endDate),
					})
				}
			}
		},
		[onChange],
	)

	const [open, { toggle, toggleOff, toggleOn }] = useToggle()
	const anchorEl = useRef(null)
	return (
		<Grid container className={className} {...gridProps}>
			<CssTextField
				type="span"
				ref={anchorEl}
				value={
					value.startDate && value.endDate
						? `${format.date(value.startDate)} - ${format.date(value.endDate)}`
						: ""
				}
				variant="outlined"
				margin="dense"
				onClick={toggleOn}
			/>
			<Popover
				open={open}
				onClose={toggleOff}
				anchorEl={anchorEl.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			>
				<DateRangePicker
					open
					maxDate={endOfTomorrow()}
					initialDateRange={value}
					toggle={toggle}
					onChange={changeHandler}
				/>
			</Popover>
		</Grid>
	)
}

export default DateRangeInput
