import type { FlagKey } from "@space-apps/honeycomb"
import type { FieldProps } from "formik"
import React from "react"
import FlagField from "./FlagField"

export type HiveNoteTagFieldProps<FormValues> = FieldProps<
	FlagKey[] | null | undefined,
	FormValues
> & { multiple?: boolean }

function HiveNoteTagField<FormValues>({
	multiple = false,
	...formik
}: HiveNoteTagFieldProps<FormValues>): React.ReactElement {
	// Component no longer relies on dynamic flag options, because backend does not send color info and reliable text info.
	// const { isLoading, data, error } = useHiveNoteOptionsQuery()

	// if (isLoading) {
	// 	return <CircularProgress />
	// }
	// if (error != null) {
	// 	return (
	// 		<Alert severity="error" variant="filled">
	// 			{(error as Error).message}
	// 		</Alert>
	// 	)
	// }
	// if (!data || data.flags.length === 0) {
	// 	return (
	// 		<Alert severity="error" variant="filled">
	// 			Nincsenek címkék!
	// 		</Alert>
	// 	)
	// }

	// const { flags } = data
	return <FlagField multiple={multiple} {...formik} />
}

export default HiveNoteTagField
