import type { Theme } from "@material-ui/core"
import { CircularProgress, useMediaQuery } from "@material-ui/core"
import type { Hive } from "@space-apps/beebox-api-client"
import {
	useHiveListQuery,
	useQuarantineListQuery,
} from "@space-apps/beebox-api-client"
import React, { useMemo } from "react"
import Map from "./Map"

export type MapContainerProps = {
	/** Predicate to filter Hives. */
	predicate?: (hive: Hive) => boolean
	fixInitialBound?: boolean
	displayLegends?: boolean
	defaultViewport?: { longitude: number; latitude: number; zoom: number }
	shouldFitToHives?: boolean
	scrollZoom?: boolean
	dragPan?: boolean
}

const MapContainer: React.FC<MapContainerProps> = ({
	predicate,
	fixInitialBound,
	displayLegends,
	defaultViewport,
	shouldFitToHives,
	scrollZoom,
	dragPan,
}) => {
	const { isLoading: isHiveListLoading, data: hiveList } = useHiveListQuery({})
	const {
		isLoading: isQuarantineListLoading,
		data: quarantineList,
	} = useQuarantineListQuery({})

	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

	const hives = useMemo(
		() =>
			hiveList?.data.map((hive) => ({
				...hive,
				isInFocus: predicate ? predicate(hive) : true,
			})),
		[hiveList, predicate],
	)
	const quarantines = quarantineList?.data

	const loading = isHiveListLoading || isQuarantineListLoading

	if (loading) {
		return <CircularProgress />
	}

	return (
		<Map
			hives={hives}
			quarantines={quarantines}
			fixInitialBound={fixInitialBound}
			displayLegends={displayLegends}
			defaultViewport={defaultViewport}
			shouldFitToHives={shouldFitToHives}
			scrollZoom={scrollZoom}
			// Drag pan false on mobile by default
			dragPan={dragPan ?? !isMobile}
		/>
	)
}

export default MapContainer
