import type { FormikConfig, FormikProps } from "formik"
import { Form, Formik } from "formik"
import isFunction from "lodash/isFunction"
import merge from "lodash/merge"
import React from "react"
import type { PartialDeep } from "type-fest"
import type { MutationDialogProps } from "./MutationDialog"
import MutationDialog from "./MutationDialog"

export type FormDialogProps<TValues, TData, TError, TVariables, TContext> = {
	formik: FormikConfig<TValues> & {
		fields:
			| React.ReactNode
			| ((formikRenderProps: FormikProps<TValues>) => React.ReactNode)
	}
	mutationDialogProps: MutationDialogProps<TData, TError, TVariables, TContext>
}

function FormDialog<TValues, TData, TError, TVariables, TContext>({
	formik,
	mutationDialogProps,
}: FormDialogProps<
	TValues,
	TData,
	TError,
	TVariables,
	TContext
>): React.ReactElement | null {
	type MDP = MutationDialogProps<TData, TError, TVariables, TContext>
	const { fields, ...formikProps } = formik
	if (!mutationDialogProps.dialog.open) {
		return null
	}
	return (
		<Formik {...formikProps}>
			{(formikRenderProps) => (
				<MutationDialog
					{...merge<MDP, PartialDeep<MDP>>(mutationDialogProps, {
						dialog: {
							components: {
								confirmButtonProps: ({ isLoading }) => ({
									onClick: () => formikRenderProps.handleSubmit(),
									disabled: isLoading || !formikRenderProps.isValid,
								}),
							},
						},
					})}
				>
					<Form>{isFunction(fields) ? fields(formikRenderProps) : fields}</Form>
				</MutationDialog>
			)}
		</Formik>
	)
}

export default FormDialog
