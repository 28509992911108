import { CircularProgress } from "@material-ui/core"
import { useSensorMeasurements24hChangeQuery } from "@space-apps/beebox-api-client"
import type { Sensor } from "@space-apps/beebox-api-client/dist/client"
import { BeeBoxMeasurementsCard } from "@space-apps/honeycomb"
import parseIso from "date-fns/parseISO"
import React, { useMemo } from "react"
import type { MinimumRequiredHiveData } from "../types/temporary-types"
import format from "../utils/format"
import sensor from "../utils/sensor"

export type HiveMeasurementsCardProps = {
	hive: MinimumRequiredHiveData
}

const HiveMeasurementsCard: React.FC<HiveMeasurementsCardProps> = ({
	hive,
}) => {
	const sensors = hive.sensors as Array<Sensor<number>>
	const weightSensor = useMemo(
		() => sensor.findOneByKey<number>(sensors, "weight"),
		[sensors],
	)
	const temperatureSensor = useMemo(
		() => sensor.findOneByKey<number>(sensors, "temp_load"),
		[sensors],
	)
	/* 	const humidity = useMemo(() => sensors.findOneByKey(sensors, "humidity"), [
		sensors,
	]) */

	const { isLoading, data: change } = useSensorMeasurements24hChangeQuery(
		weightSensor
			? {
					deviceId: weightSensor.deviceId,
					sensorKey: weightSensor.key,
			  }
			: undefined,
		{
			enabled: !!weightSensor,
		},
	)

	if (isLoading) {
		return <CircularProgress />
	}

	const lastMeasurementIsoDate = weightSensor?.measurement?.absoluteTime
	const lastMeasurementDate =
		lastMeasurementIsoDate !== undefined
			? parseIso(lastMeasurementIsoDate)
			: undefined

	return (
		<BeeBoxMeasurementsCard
			title={hive.name}
			date={lastMeasurementDate}
			rows={[
				{
					label: "Kaptár tömeg",
					value: weightSensor
						? format.kilogram(sensor.extractValue(weightSensor) ?? 0)
						: "?",
				},
				{
					label: "Tömeg változás",
					value: change != null ? format.kilogramPerDay(change) : "?",
					// TODO: tooltip
					// tooltip: "Nincs elég érték",
				},
				// {
				// 	label: "(Belső) Hőmérséklet:",
				// 	value: "TODO",
				// },
				{
					label: "Hőmérséklet", // Külső
					value: temperatureSensor
						? format.celsius(sensor.extractValue(temperatureSensor) ?? 0)
						: "?",
				},
				/* {
					label: "(Belső) Páratartalom:",
					value: `${formatNumber(humidity?.measurement.value)} %`,
				},
				{
					label: "(Külső) Páratartalom:",
					value: "TODO",
				}, */
			]}
		/>
	)
}

export default HiveMeasurementsCard
