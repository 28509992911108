import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	CircularProgress,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Alert } from "@material-ui/lab"
import type {
	Measurements,
	MeasurementValue,
	Sensor,
} from "@space-apps/beebox-api-client"
import { useMultipleSensorsMeasurementsSeries } from "@space-apps/beebox-api-client"
import React, { useMemo } from "react"
import { useIntervalContext } from "../context"
import { useToggle } from "../hooks/useToggle"
import SensorDebugChart from "./SensorDebugChart"
import SensorDebugMap from "./SensorDebugMap"
import SensorDebugTable from "./SensorDebugTable"

type SensorDebugProps = {
	sensors: Sensor[]
}

const DISPLAY_CHART_FOR_SENSOR_KEYS = [
	"battery",
	"network_signal",
	"weight",
	"temp_load",
	"postime",
	"gsm",
]

const MEDIA_SENSORS = ["picture", "video", "rec"]

const DISPLAY_MAP_FOR_SENSOR_KEYS = ["coord"]

export type MeasurementWithSensorKeyArray = Array<{
	key: string
	measurements: Measurements<MeasurementValue>
}>

function createMsDict(
	measurements: Array<Measurements<MeasurementValue>>,
	sensors: Sensor[],
): MeasurementWithSensorKeyArray {
	return measurements.map((item, index) => ({
		key: sensors[index].key,
		measurements: item,
	}))
}

type SensorAccordionProps = {
	name: string
}

const SensorAccordion: React.FC<SensorAccordionProps> = ({
	name,
	children,
}) => {
	const [toggleOpen, { toggle }] = useToggle(true)
	return (
		<Accordion expanded={toggleOpen}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggle}>
				<Typography variant="h6">{name}</Typography>
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	)
}

const SensorDebug: React.FC<SensorDebugProps> = ({ sensors }) => {
	const [interval] = useIntervalContext()

	// Media sensor measurements don't needed, it has a different page
	// And picture queries take long time
	const mediaSensors = useMemo(
		() => sensors.filter((item) => MEDIA_SENSORS.includes(item.key)),
		[sensors],
	)
	const otherSensors = useMemo(
		() => sensors.filter((item) => !MEDIA_SENSORS.includes(item.key)),
		[sensors],
	)
	const sensorParams = useMemo(() => {
		return otherSensors.map((item) => ({
			sensorKey: item.key,
			deviceId: item.deviceId,
			from: interval.from,
			to: interval.to,
		}))
	}, [otherSensors, interval.from, interval.to])

	const {
		isLoading,
		error,
		data: measurements,
	} = useMultipleSensorsMeasurementsSeries(sensorParams, {
		refetchOnWindowFocus: false,
	})

	const msDict = useMemo(() => {
		if (measurements != null) {
			return createMsDict(measurements, otherSensors)
		}
		return []
	}, [measurements, otherSensors])

	if (isLoading) {
		return <CircularProgress />
	}

	if (error != null || measurements == null) {
		return (
			<Grid container justify="center" alignItems="center">
				{error != null && (
					<Alert severity="error" variant="outlined">
						{(error as Error).message}
					</Alert>
				)}
			</Grid>
		)
	}

	function renderSensorInfo(sensor: Sensor): React.ReactElement {
		return (
			<Grid item container direction="column">
				{Object.entries(sensor).map(([key, value]) => {
					return (
						<Grid
							key={`${sensor.id}-${key}`}
							item
							container
							justify="space-between"
						>
							<Grid item xs={6}>
								<Typography>{key}</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography>{JSON.stringify(value)}</Typography>
							</Grid>
						</Grid>
					)
				})}
			</Grid>
		)
	}

	return (
		<Grid container direction="column" spacing={3}>
			<Grid xs={12} item>
				<Typography variant="h5">Szenzorok</Typography>
				<Divider />
			</Grid>
			{otherSensors.map((sensor) => (
				<Grid xs={12} item key={sensor.id}>
					<SensorAccordion name={sensor.name}>
						<Grid container direction="column" spacing={2}>
							{renderSensorInfo(sensor)}
							{DISPLAY_CHART_FOR_SENSOR_KEYS.includes(sensor.key) && (
								<Grid item>
									<SensorDebugChart
										data={
											msDict.find((item) => item.key === sensor.key)
												?.measurements ?? []
										}
										sensorKey={sensor.key}
									/>
								</Grid>
							)}
							{DISPLAY_MAP_FOR_SENSOR_KEYS.includes(sensor.key) &&
								sensor.measurement && (
									<Grid item>
										<SensorDebugMap
											// Care: temporary solution with type assertion, value is not guaranteed to be a `GeoJSON.Point`
											point={sensor.measurement.value as GeoJSON.Point}
										/>
									</Grid>
								)}
						</Grid>
					</SensorAccordion>
				</Grid>
			))}

			{mediaSensors.map((sensor) => (
				<Grid xs={12} item key={sensor.id}>
					<SensorAccordion name={sensor.name}>
						<Grid container direction="column" spacing={2}>
							{renderSensorInfo(sensor)}
						</Grid>
					</SensorAccordion>
				</Grid>
			))}
			<SensorDebugTable measurements={msDict} sensors={sensors} />
		</Grid>
	)
}

export default SensorDebug
