import merge from "lodash/merge"
import React from "react"
import type { PartialDeep } from "type-fest"
import type { MutationDialogProps } from "./MutationDialog"
import MutationDialog from "./MutationDialog"

export type ConfirmationDialogProps<TData, TError, TVariables, TContext> = {
	onConfirm: () => void
	mutationDialogProps: MutationDialogProps<TData, TError, TVariables, TContext>
}

function ConfirmationDialog<TData, TError, TVariables, TContext>({
	onConfirm,
	mutationDialogProps,
}: ConfirmationDialogProps<
	TData,
	TError,
	TVariables,
	TContext
>): React.ReactElement {
	type MDP = MutationDialogProps<TData, TError, TVariables, TContext>
	return (
		<MutationDialog
			{...merge<MDP, PartialDeep<MDP>>(mutationDialogProps, {
				dialog: {
					components: {
						confirmButtonProps: () => ({
							onClick: onConfirm,
						}),
					},
				},
			})}
		/>
	)
}

export default ConfirmationDialog
