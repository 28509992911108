import type {
	GetHiveInspectionSheetVariables,
	HiveInspectionSheet,
} from "@space-apps/beebox-api-client"
import { useHiveInspectionSheetQuery } from "@space-apps/beebox-api-client"
import type { QueryOptions } from "react-query"
import * as hiveInspectionSheet from "../../utils/hiveInspectionSheet"

function isValue(value: unknown): value is { value: string } {
	return (
		typeof value === "object" &&
		(value as { value?: string }).value !== undefined
	)
}

export function useParsedHiveInspectionSheetQuery(
	args: GetHiveInspectionSheetVariables,
	options?: QueryOptions<HiveInspectionSheet>,
): {
	data?: hiveInspectionSheet.HiveInspectionSheet
	error?: Error | null
	isLoading: boolean
} {
	const { data, isLoading, error } = useHiveInspectionSheetQuery(args, options)
	if (isValue(data)) {
		const res = hiveInspectionSheet.toPlainHiveInspectionSheet(data)
		if (res.error) {
			return { isLoading, error: res.error }
		}
		return {
			data: hiveInspectionSheet.getHiveInspectionSheet({ phis: res.phis }),
			isLoading,
		}
	}
	return { isLoading, error: error as Error | null }
}
