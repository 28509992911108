import { Button, CircularProgress, Grid } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useHiveQuery } from "@space-apps/beebox-api-client"
import { isNil } from "lodash"
import React, { useMemo } from "react"
import type { RouteComponentProps } from "react-router-dom"
import styled, { css } from "styled-components"
import AnalyticsAndMediaContainer from "../components/AnalyticsAndMediaContainer"
import HiveCharts from "../components/HiveCharts"
import HiveFlagInput from "../components/HiveFlagInput"
import HiveInfo from "../components/HiveInfo"
import HiveMap from "../components/HiveMap"
import HiveNoteListContainer from "../components/HiveNoteListContainer"
import TemperatureDeviceContainer from "../components/TemperatureDeviceContainer"
import WrapperLink from "../components/WrapperLink"
import PageWithContainer from "./PageWithContainer"
import PageWithMap from "./PageWithMap"

type MatchParams = {
	hiveId: string
}

export type HivePageProps = RouteComponentProps<MatchParams>

const ChartsWithMargin = styled(HiveCharts)(
	(props) =>
		css`
			margin-top: ${props.theme.spacing(2)}px;
			margin-bottom: ${props.theme.spacing(3)}px;
		`,
)

const HivePage: React.FC<HivePageProps> = ({ match }) => {
	const { isLoading, data: hive, error } = useHiveQuery({
		id: Number(match.params.hiveId),
	})

	const device = useMemo(() => {
		if (hive) {
			return hive.devices.find(
				(item) =>
					item.sensors.find((sensor) => sensor.includes("weight")) !==
					undefined,
			)
		}
		return undefined
	}, [hive])

	const temperatureDevice = useMemo(() => {
		if (hive) {
			return hive.devices.find(
				(item) =>
					item.sensors.find((sensor) => sensor.includes("temperature_0")) !==
					undefined,
			)
		}
		return undefined
	}, [hive])

	if (isLoading) {
		return (
			<Grid container justify="center" alignItems="center">
				<CircularProgress />
			</Grid>
		)
	}

	if (error != null || hive == null) {
		return (
			<PageWithContainer>
				<Grid container justify="center" alignItems="center">
					{error != null && (
						<Alert severity="error" variant="outlined">
							{(error as Error).message}
						</Alert>
					)}
					{hive == null && (
						<Alert severity="error" variant="outlined">
							&quot;{match.params.hiveId}&quot; azonosítójú kaptár nem létezik!
						</Alert>
					)}
				</Grid>
			</PageWithContainer>
		)
	}

	return (
		<PageWithMap
			map={<HiveMap id={hive.id} fixInitialBound />}
			title={hive.name}
		>
			<Grid container direction="column" alignItems="stretch" spacing={2}>
				<Grid item container xs={12} spacing={2}>
					<Grid item container xs={12} md={4}>
						<HiveInfo hive={hive} />
					</Grid>
					<Grid item xs={12} md={8}>
						<Grid container direction="column" spacing={2}>
							<Grid item container spacing={1}>
								{/* <Grid item>
									<WrapperLink to={`/hive/${hive.id}/camera`}>
										<Button variant="outlined">Kamerák</Button>
									</WrapperLink>
								</Grid> */}
								<Grid item>
									<WrapperLink to={`/hive/${hive.id}/inspection-sheet`}>
										<Button variant="outlined">Kaptárlap</Button>
									</WrapperLink>
								</Grid>
								<Grid item>
									<WrapperLink to={`/debug/hive/${hive.id}`}>
										<Button variant="outlined">Device debug</Button>
									</WrapperLink>
								</Grid>
								<HiveFlagInput
									id={hive.id}
									flags={hive.flags.map(({ key }) => key)}
								/>
							</Grid>

							<Grid item>
								<HiveNoteListContainer hiveId={hive.id} notesMaxHeight={400} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{!isNil(device) &&
					!isNil(device.sensors.find((item) => item.includes("weight"))) &&
					!isNil(device.sensors.find((item) => item.includes("temp_load"))) && (
						<Grid item xs={12}>
							<ChartsWithMargin deviceId={device.id} />
						</Grid>
					)}
				{!isNil(temperatureDevice) && (
					<Grid item xs={12}>
						<TemperatureDeviceContainer
							sensors={hive.sensors}
							deviceId={temperatureDevice.id}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<AnalyticsAndMediaContainer hive={hive} />
				</Grid>
			</Grid>
		</PageWithMap>
	)
}

export default HivePage
