import {
	Accordion,
	AccordionSummary,
	Grid,
	Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import type { Sensor } from "@space-apps/beebox-api-client"
import { isEmpty } from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useToggle } from "../hooks/useToggle"
import HiveMultipleTemperatureChart from "./HiveMultipleTemperatureChart"

interface TemperatureDeviceContainerProps {
	sensors: Sensor[]
	deviceId: number
}

const StyledGrid = styled(Grid)(
	(props) => css`
		padding: ${props.theme.spacing(2)}px;
	`,
)

const TemperatureDeviceContainer: React.FC<TemperatureDeviceContainerProps> = ({
	sensors,
	deviceId,
}) => {
	const [isExpanded, { toggle }] = useToggle(true)
	return (
		<Accordion
			disabled={isEmpty(sensors)}
			expanded={isExpanded}
			onChange={() => {
				toggle()
			}}
			variant="outlined"
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h5">Hőmérséklet</Typography>
			</AccordionSummary>
			<StyledGrid container direction="column" spacing={1}>
				<HiveMultipleTemperatureChart sensors={sensors} deviceId={deviceId} />
			</StyledGrid>
		</Accordion>
	)
}

export default TemperatureDeviceContainer
