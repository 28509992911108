/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useSetHiveInspectionSheetMutation } from "@space-apps/beebox-api-client"
import { useCallback, useEffect, useRef } from "react"
import { useMutation, useQueryClient } from "react-query"
import * as hiveInspectionSheet from "../../utils/hiveInspectionSheet"
import { useParsedHiveInspectionSheetQuery } from "./useParsedHiveInspectionSheetQuery"

export function useSetHiveInspectionSheetWrapperMutation<TArgs>(
	hiveId: number,
	fn: (
		args: TArgs & hiveInspectionSheet.PlainHiveInspectionSheetArgs,
	) => hiveInspectionSheet.HiveInspectionSheet,
) {
	const savedFn = useRef(fn)
	useEffect(() => {
		savedFn.current = fn
	}, [fn])

	const queryClient = useQueryClient()
	const setHiveInspectionSheetMutation = useSetHiveInspectionSheetMutation({
		onSuccess: () => {
			// Invalidating only the single query by `hiveId` inside the API client does not work reliably for some reason
			// This way all of the hive inspection sheets are invalidated, but this isn't a big performance problem now
			void queryClient.invalidateQueries("hiveInspectionSheet")
		},
	})

	const { data: phis, isLoading } = useParsedHiveInspectionSheetQuery({
		hiveId,
	})

	const wrappedFn = useCallback(
		async (args: TArgs) => {
			if (isLoading) {
				throw new Error(`Hive inspection sheet is loading`)
			}
			if (!phis) {
				throw new Error(
					`Hive inspection sheet with hive ID "${hiveId}" does not exist`,
				)
			}
			await setHiveInspectionSheetMutation.mutateAsync({
				hiveId,
				inspectionSheet: hiveInspectionSheet.toString(
					savedFn.current({ ...args, phis }),
				),
			})
		},
		[hiveId, phis, setHiveInspectionSheetMutation, isLoading],
	)

	return useMutation(wrappedFn)
}
