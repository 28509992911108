import { subDays } from "date-fns"
import React, { createContext, useContext, useState } from "react"

export type DateInterval = {
	from: Date
	to: Date
}

export type IntervalContextType = [
	DateInterval,
	(interval: DateInterval) => void,
]

const IntervalContext = createContext<IntervalContextType | undefined>(
	undefined,
)

const INITIAL_DATE = {
	from: subDays(new Date(), 7),
	to: new Date(),
}

export const IntervalContextProvider: React.FC = ({ children }) => {
	const state = useState<DateInterval>(INITIAL_DATE)
	return (
		<IntervalContext.Provider value={state}>
			{children}
		</IntervalContext.Provider>
	)
}

export function useIntervalContext(): IntervalContextType {
	const intervalContext = useContext(IntervalContext)
	if (!intervalContext) {
		throw new Error(
			"You must use `IntervalContextProvider` to access the `IntervalContext`.",
		)
	}
	return intervalContext
}
