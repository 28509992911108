import { Grid } from "@material-ui/core"
import { BeeBoxFooter } from "@space-apps/honeycomb"
import type { DateRange } from "materialui-daterange-picker"
import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import BeeBoxAppBar from "../components/BeeBoxAppBar"
import DateRangeInput from "../components/DateRangeInput"
import DrawerMenu from "../components/DrawerMenu"
import { useIntervalContext } from "../context"
import { useToggle } from "../hooks/useToggle"

export type PageProps = {
	title?: string
	withoutFooter?: boolean
}

const ContentGrid = styled(Grid)`
	display: flex;
	flex-grow: 1;
`

const Page: React.FC<PageProps> = ({
	children,
	title,
	withoutFooter = false,
}) => {
	const [interval, setDateInterval] = useIntervalContext()
	const [openDrawer, { toggle }] = useToggle()
	const history = useHistory()

	const handleInputChange = useCallback(
		(value: DateRange) => {
			setDateInterval({
				from: value.startDate ?? interval.from,
				to: value.endDate ?? interval.to,
			})
		},
		[setDateInterval, interval],
	)

	return (
		<Grid container direction="column" style={{ minHeight: "100%" }}>
			<BeeBoxAppBar
				title={title}
				onMenuClick={toggle}
				onBackIconClick={history.goBack}
				input={
					<DateRangeInput
						value={{
							startDate: interval.from,
							endDate: interval.to,
						}}
						onChange={handleInputChange}
					/>
				}
			/>
			<DrawerMenu open={openDrawer} toggleOpen={toggle} />
			<ContentGrid item>{children}</ContentGrid>

			{!withoutFooter && (
				<Grid item>
					<BeeBoxFooter
						email="office@space-apps.net"
						phone="+36 20 294 7278"
						version={process.env.REACT_APP_VERSION ?? "local"}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default Page
