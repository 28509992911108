import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useUpdateHiveMutation } from "@space-apps/beebox-api-client"
import type { FlagKey } from "@space-apps/honeycomb"
import React, { useState } from "react"
import type { FlagInputValue } from "./FlagInput"
import FlagInput from "./FlagInput"

const HiveFlagInput: React.FC<{ id: number; flags: FlagKey[] }> = ({
	id,
	flags,
}) => {
	const [value, setValue] = useState<FlagInputValue>(flags)
	const { isLoading, mutate: update } = useUpdateHiveMutation()
	return (
		<div>
			<FlagInput value={value} onChange={setValue} multiple />
			<Button
				variant="contained"
				color="primary"
				disabled={isLoading}
				onClick={() => {
					update({ id, flags: value })
				}}
			>
				{isLoading && <CircularProgress />}
				Mentés
			</Button>
		</div>
	)
}

export default HiveFlagInput
