import type { DateTimePickerProps } from "@material-ui/pickers"
import { DateTimePicker } from "@material-ui/pickers"
import type { FieldProps } from "formik"
import React from "react"

export type DateTimePickerFieldProps<FormValues> = FieldProps<
	Date,
	FormValues
> &
	Omit<DateTimePickerProps, "onChange">

/**
 * Based on https://material-ui-pickers.dev/guides/form-integration
 * Type assertions are applied liberally because JS code is pasted from official docs.
 */
function DateTimePickerField<FormValues>({
	field,
	form,
	...props
}: DateTimePickerFieldProps<FormValues>): React.ReactElement {
	// @ts-expect-error: TS doesn't recognize the index signature
	const currentError = form.errors[field.name] as string
	return (
		<DateTimePicker
			name={field.name}
			value={field.value}
			helperText={currentError}
			error={Boolean(currentError)}
			onError={(error) => {
				// handle as a side effect
				if (error != null && error !== "" && error !== currentError) {
					form.setFieldError(field.name, error as string)
				}
			}}
			// if you are using custom validation schema you probably want to pass `true` as third argument
			onChange={(date) => form.setFieldValue(field.name, date, false)}
			{...props}
		/>
	)
}

export default DateTimePickerField
