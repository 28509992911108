import { CircularProgress, Grid } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import type { Sensor } from "@space-apps/beebox-api-client"
import {
	useMultipleSensorsMeasurementsSeries,
	useSensorMeasurementsSeriesQuery,
} from "@space-apps/beebox-api-client"
import { NumberMeasurementChart, TemperatureChart } from "@space-apps/honeycomb"
import { isEmpty } from "lodash"
import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import { useIntervalContext } from "../context"
import { convertMeasurementsToSerie } from "../utils/chart"

interface HiveMultipleTemperatureChartProps {
	deviceId: number
	sensors: Sensor[]
}

const TemperatureChartDiv = styled.div(
	() => css`
		height: 360px;
	`,
)

const BatteryChartDiv = styled.div(
	() => css`
		height: 200px;
	`,
)

const HiveMultipleTemperatureChart: React.FC<HiveMultipleTemperatureChartProps> = ({
	sensors,
	deviceId,
}) => {
	const [interval] = useIntervalContext()

	const {
		isLoading: isBatteryLoading,
		data: batteryMeasurements,
	} = useSensorMeasurementsSeriesQuery<number>({
		...interval,
		deviceId,
		sensorKey: "battery",
	})

	const temperatureSensors = useMemo(
		() => sensors.filter((item) => item.key.includes("temperature")),
		[sensors],
	)

	const sources = temperatureSensors.map((sensor, index) => ({
		...sensor,
		...interval,
		sensorKey: sensor.key,
		dataLabel: `Hőmérséklet ${index}`,
	}))
	const { isLoading, data, error } = useMultipleSensorsMeasurementsSeries<
		number
	>(sources)

	const chartData = useMemo(() => {
		if (data) {
			return data.map((measurements, i) =>
				convertMeasurementsToSerie(
					sources[i].dataLabel,
					measurements,
					interval,
				),
			)
		}
		return []
	}, [data, interval, sources])

	if (!batteryMeasurements || isEmpty(batteryMeasurements)) {
		return (
			<Alert severity="warning" variant="outlined">
				Nem található adat a kiválasztott időszakhoz
			</Alert>
		)
	}

	const batteryData = convertMeasurementsToSerie(
		"Battery",
		batteryMeasurements,
		interval,
	)

	if (isLoading || isBatteryLoading) {
		return <CircularProgress />
	}

	if (error != null) {
		return (
			<Alert severity="error" variant="outlined">
				{(error as Error).message}
			</Alert>
		)
	}
	return (
		<Grid item xs={12}>
			<TemperatureChartDiv>
				{data && (
					<TemperatureChart
						min={20}
						max={30}
						key={Math.random()}
						data={chartData.filter(
							(value) =>
								!isEmpty(
									value.data.filter((element) => element.value !== null),
								),
						)}
						displayLegends
					/>
				)}
			</TemperatureChartDiv>
			<BatteryChartDiv>
				<NumberMeasurementChart min={3.6} max={4.2} data={[batteryData]} />
			</BatteryChartDiv>
		</Grid>
	)
}

export default HiveMultipleTemperatureChart
