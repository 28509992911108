import { CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useMultipleSensorsMeasurementsSeries } from "@space-apps/beebox-api-client"
import type {
	HiveInHiveGroup,
	Sensor,
} from "@space-apps/beebox-api-client/dist/client"
import { MassChart } from "@space-apps/honeycomb"
import { isEmpty } from "lodash"
import React, { useMemo } from "react"
import styled from "styled-components"
import { useIntervalContext } from "../context"
import { convertMeasurementsToSerie } from "../utils/chart"

type HiveGroupMassChartProps = {
	sensors: Sensor[]
	hives: HiveInHiveGroup[]
}

const ChartDiv = styled.div`
	height: 20rem;
`

const HiveGroupMassChart: React.FC<HiveGroupMassChartProps> = ({
	sensors,
	hives,
}) => {
	const [interval] = useIntervalContext()

	const weightSensors = useMemo(
		() => sensors.filter((item) => item.key === "weight"),
		[sensors],
	)
	const sources = weightSensors.map((sensor) => ({
		...sensor,
		...interval,
		sensorKey: sensor.key,
		dataLabel:
			hives.find((hive) => hive.id === sensor.hiveId)?.name ??
			`Ismeretlen Kaptár ${sensor.hiveId}`,
	}))
	const { isLoading, data, error } = useMultipleSensorsMeasurementsSeries<
		number
	>(sources)

	const chartData = useMemo(() => {
		if (data) {
			return data.map((measurements, i) =>
				convertMeasurementsToSerie(
					sources[i].dataLabel,
					measurements,
					interval,
				),
			)
		}
		return []
	}, [data, interval, sources])

	if (isLoading) {
		return <CircularProgress />
	}
	if (error != null) {
		return (
			<Alert severity="error" variant="outlined">
				{(error as Error).message}
			</Alert>
		)
	}
	if (!data) {
		return (
			<Alert severity="error" variant="outlined">
				Nincs elég adat
			</Alert>
		)
	}
	return (
		<ChartDiv>
			<MassChart
				// Need key to full rerender when props changed
				// otherwise dots disappearing
				key={Math.random()}
				data={chartData}
				displayLegends={!isEmpty(chartData)}
			/>
		</ChartDiv>
	)
}

export default HiveGroupMassChart
