import { Hidden } from "@material-ui/core"
import type { HiveGroup } from "@space-apps/beebox-api-client"
import React from "react"
import HiveGroupMassChart from "./HiveGroupMassChart"
import HiveGroupMeasurementsCard from "./HiveGroupMeasurementsCard"
import MeasurementsCardAndChartGrid from "./MeasurementsCardAndChartGrid"

type HiveGroupInfoProps = {
	hiveGroup: HiveGroup
}

const HiveGroupInfo: React.FC<HiveGroupInfoProps> = ({ hiveGroup }) => {
	return (
		<MeasurementsCardAndChartGrid
			measurementsCard={<HiveGroupMeasurementsCard hiveGroup={hiveGroup} />}
			chart={
				<Hidden xsDown>
					<HiveGroupMassChart
						sensors={hiveGroup.sensors}
						hives={hiveGroup.hives}
					/>
				</Hidden>
			}
		/>
	)
}

export default HiveGroupInfo
