import { CircularProgress, Grid } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import type { DeviceInHive, Sensor } from "@space-apps/beebox-api-client"
import { useHiveGroupQuery } from "@space-apps/beebox-api-client"
import React from "react"
import type { RouteComponentProps } from "react-router-dom"
import styled, { css } from "styled-components"
import HiveGroupMap from "../components/HiveGroupMap"
import HiveGroupMassChart from "../components/HiveGroupMassChart"
import HiveGroupInfoCard from "../components/HiveGroupMeasurementsCard"
import HivePictogramList from "../components/HivePictogramList"
import PageWithContainer from "./PageWithContainer"
import PageWithMap from "./PageWithMap"

type MatchParams = {
	hiveGroupId: string
}

export type HiveGroupPageProps = RouteComponentProps<MatchParams>

const CardContainerGrid = styled(Grid)(
	(props) => css`
		${props.theme.breakpoints.down("xs")} {
			justify-content: center;
		}
	`,
)
const CardGrid = styled(Grid)`
	width: 20rem;
`

const HiveGroupPage: React.FC<HiveGroupPageProps> = ({ match }) => {
	const { isLoading, data: hiveGroup, error } = useHiveGroupQuery({
		id: Number(match.params.hiveGroupId),
	})

	if (isLoading) {
		return (
			<PageWithContainer>
				<Grid container justify="center" alignItems="center">
					<CircularProgress />
				</Grid>
			</PageWithContainer>
		)
	}

	if (error != null || hiveGroup == null) {
		return (
			<PageWithContainer>
				<Grid container justify="center" alignItems="center">
					{error != null && (
						<Alert severity="error" variant="outlined">
							{(error as Error).message}
						</Alert>
					)}
					{hiveGroup == null && (
						<Alert severity="error" variant="outlined">
							&quot;{match.params.hiveGroupId}&quot; azonosítójú méhes nem
							létezik!
						</Alert>
					)}
				</Grid>
			</PageWithContainer>
		)
	}

	return (
		<PageWithMap
			map={<HiveGroupMap id={hiveGroup.id} fixInitialBound />}
			title={hiveGroup.name}
		>
			<Grid container spacing={3} direction="column" alignItems="stretch">
				<Grid container spacing={2} xs={12} item justify="space-between">
					<CardContainerGrid item container xs={12} md={4}>
						{/* This Grid item prevents the Card height fill the remaining space */}
						<CardGrid item>
							<HiveGroupInfoCard hiveGroup={hiveGroup} link={false} />
						</CardGrid>
					</CardContainerGrid>
					<Grid item xs={12} md={8}>
						<HiveGroupMassChart
							sensors={hiveGroup.sensors}
							hives={hiveGroup.hives}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<HivePictogramList
						hives={hiveGroup.hives.map((hive) => ({
							...hive,
							devices: hive.devices.map((deviceId) =>
								hiveGroup.devices.find((device) => device.id === deviceId),
							) as DeviceInHive[],
							sensors: hive.sensors.map((sensorId) =>
								hiveGroup.sensors.find((sensor) => sensor.id === sensorId),
							) as Array<Sensor<number>>,
						}))}
					/>
				</Grid>
			</Grid>
		</PageWithMap>
	)
}

export default HiveGroupPage
