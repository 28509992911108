import { Alert } from "@material-ui/lab"
import type {
	Measurements,
	MeasurementValue,
} from "@space-apps/beebox-api-client"
import { NumberMeasurementChart } from "@space-apps/honeycomb"
import { isEmpty } from "lodash"
import React from "react"
import { convertMeasurementsToData } from "../utils/chart"

interface SensorDebugChartProps {
	data: Measurements<MeasurementValue>
	sensorKey: string
}

const SensorDebugChart: React.FC<SensorDebugChartProps> = ({
	data,
	sensorKey,
}) => {
	const chartData = convertMeasurementsToData(data as Measurements<number>)

	if (isEmpty(data)) {
		return <Alert severity="warning">Nincs adat</Alert>
	}
	return (
		<div style={{ height: 350 }}>
			<NumberMeasurementChart data={[{ name: sensorKey, data: chartData }]} />
		</div>
	)
}

export default SensorDebugChart
