import { Grid } from "@material-ui/core"
import type { Hive } from "@space-apps/beebox-api-client"
import React from "react"
import styled, { css } from "styled-components"
import HiveMeasurementsCard from "./HiveMeasurementsCard"
import HivePictogramContainer from "./HivePictogramContainer"

export type HiveInfoProps = {
	hive: Hive
}

const PictogramGrid = styled(Grid)`
	height: 26rem;
	width: 22rem;
`

const CardGrid = styled(Grid)`
	width: 22rem;
`

const ContainerGrid = styled(Grid)(
	(props) => css`
		${props.theme.breakpoints.down("sm")} {
			justify-content: center;
		}
		${props.theme.breakpoints.up("sm")} {
			justify-content: "flex-start";
		}
	`,
)

const HiveInfo: React.FC<HiveInfoProps> = ({ hive }) => {
	return (
		<ContainerGrid container spacing={3}>
			<CardGrid item>
				<HiveMeasurementsCard hive={hive} />
			</CardGrid>
			<PictogramGrid item>
				<HivePictogramContainer hive={hive} editable />
			</PictogramGrid>
		</ContainerGrid>
	)
}

export default HiveInfo
