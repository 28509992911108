import { IconButton } from "@material-ui/core"
import type { ColDef } from "@material-ui/data-grid"
import { DataGrid } from "@material-ui/data-grid"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import React, { useCallback, useMemo, useState } from "react"
import { useDeleteHiveInspectionSheetEntryMutation } from "../hooks/hiveInspectionSheet/useDeleteHiveInspectionSheetEntryMutation"
import format from "../utils/format"
import type {
	HiveInspectionSheet,
	HiveInspectionSheetEntry,
} from "../utils/hiveInspectionSheet"
import ConfirmationDialog from "./ConfirmationDialog"
import { HiveInspectionSheetFormDialog } from "./HiveInspectionSheetFormDialog"

type HiveInspectionSheetTableProps = {
	hiveInspectionSheet: HiveInspectionSheet
}

const defaultColumns: ColDef[] = [
	{ field: "queenId", headerName: "Anya azonosító", width: 130 },
	{ field: "population", headerName: "Nép", width: 100 },
	{ field: "comb", headerName: "Lép", width: 100 },
	{ field: "open", headerName: "Nyit", width: 100 },
	{ field: "closed", headerName: "Fed", width: 100 },
	{ field: "food", headerName: "Élelem (kg)", width: 150 },
	{ field: "ab", headerName: "AB (db)", width: 150 },
	{ field: "calm", headerName: "Nyugodt", width: 150 },
	{ field: "stay", headerName: "Lépen maradás", width: 200 },
	{ field: "clean", headerName: "Tisztogató hajlam", width: 250 },
	{ field: "swarm", headerName: "Rajzási hajlam", width: 200 },
	{ field: "empty", headerName: "Üres", width: 100 },
	{ field: "fakecomb", headerName: "Műlép", width: 100 },
	{ field: "bee", headerName: "Méh", width: 100 },
	{ field: "brood", headerName: "Fias", width: 100 },
	{ field: "honey", headerName: "Méz", width: 100 },
	{ field: "sugar", headerName: "Cukor (kg)", width: 150 },
	{ field: "type", headerName: "Kaptár típus", width: 150 },
	{ field: "size", headerName: "Keretméret", width: 150 },
	{ field: "comment", headerName: "Megjegyzés", width: 200 },
]

const HiveInspectionSheetTable: React.FC<HiveInspectionSheetTableProps> = ({
	hiveInspectionSheet,
}) => {
	const [
		hiveInspectionSheetEntryToEdit,
		setHiveInspectionSheetEntryToEdit,
	] = useState<HiveInspectionSheetEntry>()
	const [
		hiveInspectionSheetEntryToDelete,
		setHiveInspectionSheetEntryToDelete,
	] = useState<HiveInspectionSheetEntry>()
	const deleteEntryMutation = useDeleteHiveInspectionSheetEntryMutation({
		hiveId: hiveInspectionSheet.hiveId,
	})

	const renderActionsCell = useCallback<Required<ColDef>["renderCell"]>(
		({ value: id }) => (
			<div>
				<IconButton
					aria-label="edit"
					size="small"
					onClick={() =>
						setHiveInspectionSheetEntryToEdit(
							hiveInspectionSheet.entries.get(id as string),
						)
					}
				>
					<EditIcon fontSize="small" />
				</IconButton>
				<IconButton
					aria-label="delete"
					size="small"
					onClick={() =>
						setHiveInspectionSheetEntryToDelete(
							hiveInspectionSheet.entries.get(id as string),
						)
					}
				>
					<DeleteIcon fontSize="small" />
				</IconButton>
			</div>
		),
		[hiveInspectionSheet.entries],
	)
	const columns = useMemo<ColDef[]>(
		() => [
			{
				field: "id",
				headerName: "Műveletek",
				renderCell: renderActionsCell,
			},
			{
				field: "date",
				headerName: "Dátum",
				width: 130,
				renderCell: ({ value }) => {
					if (value instanceof Date) {
						return <div>{format.date(value)}</div>
					}
					return <div />
				},
			},
			...defaultColumns,
		],
		[renderActionsCell],
	)

	return (
		<div style={{ height: 400, width: "100%" }}>
			<DataGrid
				rows={Array.from(hiveInspectionSheet.entries.values())}
				columns={columns}
				disableSelectionOnClick
				filterModel={{
					items: [
						{
							columnField: "queenId",
							operatorValue: "equals",
							value: hiveInspectionSheet.queenId,
						},
					],
				}}
			/>

			{hiveInspectionSheetEntryToEdit && (
				<HiveInspectionSheetFormDialog
					queenId={hiveInspectionSheet.queenId}
					hiveId={hiveInspectionSheet.hiveId}
					initialValues={hiveInspectionSheetEntryToEdit}
					open
					onClose={() => setHiveInspectionSheetEntryToEdit(undefined)}
				/>
			)}

			{hiveInspectionSheetEntryToDelete && (
				<ConfirmationDialog
					mutationDialogProps={{
						dialog: {
							open: true,
							onClose: () => setHiveInspectionSheetEntryToDelete(undefined),
							components: {
								title: "Biztosan törli ezt a kaptárlap bejegyzést?",
							},
						},
						result: deleteEntryMutation,
					}}
					onConfirm={async () => {
						await deleteEntryMutation.mutateAsync({
							entryId: hiveInspectionSheetEntryToDelete.id,
						})
					}}
				/>
			)}
		</div>
	)
}

export default HiveInspectionSheetTable
