import { CircularProgress, TextField } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import {
	useSensorMeasurements24hChangesQuery,
	useUpdateHiveGroupMutation,
} from "@space-apps/beebox-api-client"
import type {
	HiveGroup,
	Sensor,
} from "@space-apps/beebox-api-client/dist/client"
import {
	BeeBoxMeasurementsCard,
	MeasurementsCardActionButton,
} from "@space-apps/honeycomb"
import { Field } from "formik"
import React from "react"
import { useToggle } from "../hooks/useToggle"
import format from "../utils/format"
import math from "../utils/math"
import sensorUtil from "../utils/sensor"
import FormDialog from "./FormDialog"
import WrapperLink from "./WrapperLink"

export type HiveGroupMeasurementsCardProps = {
	hiveGroup: HiveGroup
	link?: boolean
}

const HiveGroupMeasurementsCard: React.FC<HiveGroupMeasurementsCardProps> = ({
	hiveGroup,
	link = true,
}) => {
	const deviceIdAndSensorKeyObjects = hiveGroup.sensors
		.filter((sensor) => sensor.key === "weight")
		.map((sensor) => ({ deviceId: sensor.deviceId, sensorKey: sensor.key }))
	const {
		isLoading,
		data: changes,
		error,
	} = useSensorMeasurements24hChangesQuery(deviceIdAndSensorKeyObjects, {
		enabled: deviceIdAndSensorKeyObjects.length > 0,
	})

	const linkComponent = link ? (
		<WrapperLink to={`/hivegroup/${hiveGroup.id}`}>
			<MeasurementsCardActionButton>Bővebben</MeasurementsCardActionButton>
		</WrapperLink>
	) : undefined

	const [open, { toggleOn: handleOpen, toggleOff: handleClose }] = useToggle(
		false,
	)
	const updateHiveGroupMutation = useUpdateHiveGroupMutation()
	const dialog = (
		<FormDialog
			mutationDialogProps={{
				dialog: {
					open,
					onClose: handleClose,
				},
				result: updateHiveGroupMutation,
			}}
			formik={{
				enableReinitialize: true,
				isInitialValid: false,
				initialValues: {
					name: hiveGroup.name,
				},
				onSubmit: async ({ name }) =>
					updateHiveGroupMutation.mutateAsync({ id: hiveGroup.id, name }),
				fields: (
					<Field
						as={TextField}
						name="name"
						required
						label="Név"
						variant="outlined"
					/>
				),
			}}
		/>
	)

	if (isLoading) {
		return <CircularProgress />
	}

	if (error != null) {
		return (
			<Alert severity="error" variant="outlined">
				{(error as Error).message}
			</Alert>
		)
	}

	if (!changes) {
		return (
			<>
				<BeeBoxMeasurementsCard
					title={hiveGroup.name}
					onEdit={handleOpen}
					rows={[{ label: "Nincs megjeleníthető adat!", value: "" }]}
					actions={linkComponent}
				/>
				{dialog}
			</>
		)
	}

	const weightSensors = hiveGroup.sensors.filter(
		(sensor) => sensor.key === "weight",
	) as Array<Sensor<number>>

	// This works because currently 1 hive has 1 device which has 1 weight sensor,
	// but it's possible that this changes in the future.
	const inaccurate =
		hiveGroup.devices.length > deviceIdAndSensorKeyObjects.length
	const sum = sensorUtil.sum(weightSensors)
	const avg = sensorUtil.avg(weightSensors)

	const validChanges = changes.filter<number>(
		(change): change is number => change != null,
	)
	const changesAreInvalid = validChanges.length === 0
	// TODO: tooltip
	// const changesAreInaccurate =
	// 	changesAreInvalid || changes.length > validChanges.length
	const sumOfChanges = math.sum(validChanges)
	const avgChanges = math.avg(validChanges)

	return (
		<>
			<BeeBoxMeasurementsCard
				title={hiveGroup.name}
				onEdit={handleOpen}
				rows={
					inaccurate
						? [
								{
									label: "Nincs minden kaptárhoz mérleg!",
									value: "",
								},
						  ]
						: [
								{
									label: "Összesített kaptár tömeg",
									value: format.kilogram(sum),
								},
								{
									label: "Átlagos kaptár tömeg",
									value: format.kilogram(avg),
								},
								{
									label: "Összesített tömeg változás",
									value: !changesAreInvalid
										? format.kilogramPerDay(sumOfChanges)
										: "?",
								},
								{
									label: "Átlagos tömeg változás",
									value: !changesAreInvalid
										? format.kilogramPerDay(avgChanges)
										: "?",
								},
						  ]
				}
				actions={linkComponent}
			/>
			{dialog}
		</>
	)
}

export default HiveGroupMeasurementsCard
