import {
	Fab,
	FormControl,
	Radio,
	RadioGroup,
	useTheme,
} from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import {
	Close,
	FiberManualRecord as ColorCircle,
	Layers,
} from "@material-ui/icons"
import type { Hive, Quarantine } from "@space-apps/beebox-api-client"
import { BeeBoxLogoIcon } from "@space-apps/honeycomb"
import * as turf from "@turf/turf"
import qs from "qs"
import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import type * as ReactMapGL from "react-map-gl"
import InteractiveMap, {
	Layer,
	LinearInterpolator,
	NavigationControl,
	Source,
	WebMercatorViewport,
} from "react-map-gl"
import styled from "styled-components"
import { useToggle } from "../hooks/useToggle"
import * as gis from "../utils/gis"
import { HiveMarker } from "./HiveMarker"

// const CALYPSO_URL = process.env.REACT_APP_CALYPSO_URL ?? "/ng/calypso"

const EMPTY_STYLE = {
	version: 8,
	sources: {},
	layers: [],
}

const osm = {
	source: {
		id: "osm",
		type: "raster",
		tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
		tileSize: 256,
	},
	layer: {
		id: "osm",
		type: "raster",
		source: "osm",
		paint: {},
		layout: {},
	},
}

// Example Sentinal Configuration:
// const baseUrl =
// 	"https://services.sentinel-hub.com/ogc/wms/567b798e-bda4-499e-8a3e-75ac2de3ca31"
// const sentinelHub = {
// 	tileSize: 512,
// 	attribution:
// 		'&copy; <a href="http://www.sentinel-hub.com/" target="_blank">Sentinel Hub</a>',
// 	urlProcessingApi:
// 		"https://services.sentinel-hub.com/ogc/wms/aeafc74a-c894-440b-a85b-964c7b26e471",
// 	maxcc: 20,
// 	minZoom: 6,
// 	maxZoom: 16,
// 	preset: "FALSE_COLOR_URBAN",
// 	layers: "FALSE_COLOR_URBAN",
// 	time: "2020-04-01/2020-10-27",
// }

type WMS111TileLayerOptions = {
	layers: string | string[]
	width?: number
	height?: number
	styles?: string | string[]
	format?: string
	transparent?: boolean
	time?: string
}

function createWMS111TileSourceURL(
	baseURL: string,
	options?: WMS111TileLayerOptions,
): string {
	const defaultOptions: Partial<WMS111TileLayerOptions> = {
		width: 512,
		height: 512,
		format: "image/png",
		transparent: true,
	}

	const mergedOptions = { ...defaultOptions, ...options }

	const theWMSDefaults = {
		version: "1.1.1",
		service: "WMS",
		request: "GetMap",
		srs: "EPSG:3857",
		bbox: "{bbox-epsg-3857}",
	}

	let skip = false
	const skipKeys = ["bbox"]
	const params = { ...theWMSDefaults, ...mergedOptions }
	const stringifiedParams = qs.stringify(params, {
		arrayFormat: "comma",
		encoder: (str, defaultEncoder, charset, type) => {
			if (!skip && type === "key" && skipKeys.includes(str)) {
				skip = true
			} else if (skip) {
				skip = false
				return str as string
			}
			return defaultEncoder(str, defaultEncoder, charset)
		},
	})

	return `${baseURL}?${stringifiedParams}`
}

const PRC_LEGENDS = [
	{
		id: "blue",
		value: "Kukorica",
		color: "blue",
	},
	{
		id: "green",
		value: "Szőlő",
		color: "green",
	},
	{
		id: "yellow",
		value: "Gabona",
		color: "yellow",
	},
	{
		id: "red",
		value: "Gyep",
		color: "red",
	},
	{
		id: "purple",
		value: "Napraforgó",
		color: "purple",
	},
	{
		id: "turquoise",
		value: "Erdő",
		color: "turquoise",
	},
]

const satelliteLayers = {
	/* 	nrgb: {
		label: "Valósszínes",
		source: {
			id: "nrgb",
			type: "raster",
			tiles: [
				createWMS111TileSourceURL(
					"https://services.sentinel-hub.com/ogc/wms/567b798e-bda4-499e-8a3e-75ac2de3ca31",
					{
						layers: "NATURAL-RGB",
					},
				),
			],
			tileSize: 512,
		},
		layer: {
			id: "n2rgb",
			type: "raster",
			source: "n2rgb",
			paint: {},
			layout: {},
		},
	},
	ndvi: {
		label: "Növény egészségügy",
		source: {
			id: "ndvi",
			type: "raster",
			tiles: [
				createWMS111TileSourceURL(
					"https://services.sentinel-hub.com/ogc/wms/567b798e-bda4-499e-8a3e-75ac2de3ca31",
					{
						layers: "NDVI_NEW",
					},
				),
			],
			tileSize: 512,
		},
		layer: {
			id: "ndvi",
			type: "raster",
			source: "ndvi",
			paint: {},
			layout: {},
		},
	}, */
	/* prc: {
		label: "Növény típusok",
		source: {
			id: "prc",
			type: "raster",
			tiles: [
				createWMS111TileSourceURL(CALYPSO_URL, {
					styles: "",
					layers: "prc",
				}),
			],
			tileSize: 512,
		},
		layer: {
			id: "prc",
			type: "raster",
			source: "prc",
			paint: {},
			layout: {},
		},
	}, */

	beeBoxRGB: {
		label: "Valósszínes",
		source: {
			id: "BeeBox-RGB",
			type: "raster",
			tiles: [
				createWMS111TileSourceURL(
					"https://www.space-apps.org/geoserver/BeeBox-RGB/wms",
					{
						styles: "",
						layers: "BeeBox-RGB:BeeBox-RGB-layerGroup",
						transparent: true,
					},
				),
			],
			tileSize: 512,
		},
		layer: {
			id: "BeeBox-RGB",
			type: "raster",
			source: "BeeBox-RGB",
			paint: {},
			layout: {},
		},
	},
	falseIR: {
		label: "Növény egészségügy",
		source: {
			id: "FalseIR",
			type: "raster",
			tiles: [
				createWMS111TileSourceURL(
					"https://www.space-apps.org/geoserver/BeeBox-FalseIR/wms",
					{
						styles: "",
						layers: "BeeBox-FalseIR:BeeBox-FalseIR-layerGroup",
						transparent: true,
					},
				),
			],
			tileSize: 512,
		},
		layer: {
			id: "FalseIR",
			type: "raster",
			source: "FalseIR",
			paint: {},
			layout: {},
		},
	},
	radar: {
		label: "Növény típusok",
		source: {
			id: "radar",
			type: "raster",
			tiles: [
				createWMS111TileSourceURL(
					"https://www.space-apps.org/geoserver/BeeBox-Radar/wms",
					{
						styles: "",
						layers: "BeeBox-Radar:BeeBox-Radar-layerGroup",
						transparent: true,
					},
				),
			],
			tileSize: 512,
		},
		layer: {
			id: "radar",
			type: "raster",
			source: "radar",
			paint: {},
			layout: {},
		},
	},
}
type SatelliteLayerKey = keyof typeof satelliteLayers
const satelliteLayersOrder: SatelliteLayerKey[] = [
	"beeBoxRGB",
	"falseIR",
	"radar",
]

const quarantine = {
	label: "Karantén",
	source: {
		id: "vec",
		type: "raster",
		tiles: [
			createWMS111TileSourceURL(
				"https://www.space-apps.org/geoserver/Karanten/wms",
				{
					styles: "",
					layers: "Karanten:BeeBox-Karanten-layerGroup",
					transparent: true,
				},
			),
		],
		tileSize: 512,
	},
	layer: {
		id: "Karanten",
		type: "raster",
		source: "Karanten",
		// "source-layer": "vec",
		paint: {},
		layout: {},
	},
}

export type MapProps = {
	hives?: Array<Hive & { isInFocus: boolean }>
	quarantines?: Quarantine[]
	fixInitialBound?: boolean
	displayLegends?: boolean
	defaultViewport?: { longitude: number; latitude: number; zoom: number }
	shouldFitToHives?: boolean
	scrollZoom?: boolean
	dragPan?: boolean
}

const StyledNavigationControlContainer = styled(Grid)`
	position: absolute;
	bottom: 8px;
	right: 8px;
	z-index: 1;
`

const StyledLayerControl = styled(Paper)`
	padding: 0 4px;
	position: absolute;
	top: 48px;
	left: 8px;
	z-index: 1;
`

const StyledFabContainer = styled.div`
	padding: 0 4px;
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: 1;
`

const StyledLegendContainer = styled(Paper)`
	padding: 0 4px;
	position: absolute;
	right: 8px;
	top: 8px;
	z-index: 1;
`

const StyledLogoContainer = styled.div`
	padding: 0 4px;
	position: absolute;
	left: 8px;
	bottom: 8px;
	z-index: 1;
`

/**
 * Based on Mapbox GL `_isOutOfMapMaxBounds` implementation.
 * See: https://github.com/mapbox/mapbox-gl-js/blob/12991995fa1bb62c5ecdb0282c7c142578b897e5/src/ui/control/geolocate_control.js#L164
 * Cannot use Mapbox GL `maxBounds` property because `react-map-gl` is not using Mapbox GL's built-in interaction handling logic.
 */
function isOutOfBounds(
	viewport: ReactMapGL.ViewportProps,
	maxBounds?: gis.Bbox,
): boolean {
	if (!maxBounds) {
		return false
	}
	const { longitude: lng, latitude: lat } = viewport
	const { minlng, minlat, maxlng, maxlat } = maxBounds
	return lng < minlng || lng > maxlng || lat < minlat || lat > maxlat
}

const Map: React.FC<MapProps> = ({
	hives,
	quarantines,
	defaultViewport,
	shouldFitToHives = true,
	fixInitialBound = false,
	displayLegends = false,
	scrollZoom = false,
	dragPan = true,
}) => {
	const theme = useTheme()

	const [showLayers, { toggle: toggleLayers }] = useToggle()

	const boundsInitialized = useRef(false)
	const [viewport, setViewport] = useState<
		Partial<ReactMapGL.ViewportProps> | undefined
	>(defaultViewport)

	const hivesInFocus = useMemo(
		() => hives?.filter(({ isInFocus }) => isInFocus),
		[hives],
	)

	const [initialBounds, setInitialBounds] = useState<gis.Bbox | undefined>(
		undefined,
	)
	// Initializes viewport with a bounding box that contains all of the focused hives,
	// does not apply new viewport on purpose when `hives` prop changes.
	useEffect(() => {
		if (
			shouldFitToHives &&
			!boundsInitialized.current &&
			viewport &&
			hivesInFocus &&
			hivesInFocus.length > 0
		) {
			const { minlng, minlat, maxlng, maxlat } = gis.bboxFromPolygons(
				hivesInFocus.map(({ geojson }) => geojson),
			)
			const wmv = new WebMercatorViewport(viewport).fitBounds([
				[minlng, minlat],
				[maxlng, maxlat],
			])
			// Set zoom one level higher to better view when more hive present
			if (hivesInFocus.length > 1) {
				setViewport({ ...wmv, zoom: wmv.zoom - 1 })
			} else {
				setViewport(wmv)
			}
			boundsInitialized.current = true
			setInitialBounds({
				minlng,
				minlat,
				maxlng,
				maxlat,
			})
		}
	}, [shouldFitToHives, viewport, hivesInFocus])

	const handleViewportChange = useCallback(
		(nextViewport: ReactMapGL.ViewportProps) => {
			if (fixInitialBound && isOutOfBounds(nextViewport, initialBounds)) {
				return
			}
			setViewport(nextViewport)
		},
		[initialBounds, fixInitialBound],
	)

	const createClickHandler = useCallback(
		(polygon: GeoJSON.Feature<GeoJSON.Polygon>, around: number[]) => () => {
			const [minlng, minlat, maxlng, maxlat] = turf.bbox(polygon)
			const { longitude, latitude, zoom } = new WebMercatorViewport(
				viewport,
			).fitBounds([
				[minlng, minlat],
				[maxlng, maxlat],
			])
			setViewport((prevViewport) => ({
				...prevViewport,
				longitude,
				latitude,
				zoom,
				transitionInterpolator: new LinearInterpolator({
					around,
				}),
				transitionDuration: 1000,
			}))
		},
		[viewport],
	)

	const [satelliteLayer, setSatelliteLayer] = useState<
		SatelliteLayerKey | "none"
	>("beeBoxRGB")
	const handleSatelliteLayerChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSatelliteLayer(event.target.value as SatelliteLayerKey | "none")
		},
		[],
	)

	return (
		<InteractiveMap
			mapStyle={EMPTY_STYLE}
			interactiveLayerIds={hives?.map(({ id }) => id.toString())}
			{...viewport}
			width="100%"
			height="100%"
			scrollZoom={scrollZoom}
			dragPan={dragPan}
			onViewportChange={handleViewportChange}
		>
			<StyledFabContainer>
				{!showLayers ? (
					<Fab onClick={toggleLayers} size="medium">
						<Layers />
					</Fab>
				) : (
					<Fab
						style={{ backgroundColor: "#DC143C" }}
						size="medium"
						onClick={toggleLayers}
					>
						<Close htmlColor="white" />
					</Fab>
				)}

				<StyledLayerControl>
					{showLayers && (
						<FormControl component="fieldset">
							{/* <FormLabel component="legend">Műholdkép</FormLabel> */}
							<RadioGroup
								aria-label="satellite-layer"
								name="satellite-layer"
								value={satelliteLayer}
								onChange={handleSatelliteLayerChange}
							>
								<FormControlLabel
									key="none"
									value="none"
									control={<Radio />}
									label="Térkép"
								/>
								{satelliteLayersOrder.map((id) => (
									<FormControlLabel
										key={id}
										value={id}
										control={<Radio />}
										label={satelliteLayers[id].label}
									/>
								))}
							</RadioGroup>
						</FormControl>
					)}
				</StyledLayerControl>
			</StyledFabContainer>

			{satelliteLayer === "radar" && displayLegends && (
				<StyledLegendContainer>
					<Grid container direction="column" spacing={2}>
						{PRC_LEGENDS.map((item) => (
							<Grid item container key={item.id} alignItems="center">
								<ColorCircle style={{ color: item.color }} />
								<Grid item>{item.value}</Grid>
							</Grid>
						))}
					</Grid>
				</StyledLegendContainer>
			)}

			<StyledNavigationControlContainer
				container
				direction="column"
				alignItems="flex-end"
				spacing={1}
			>
				<Grid item>
					<NavigationControl />
				</Grid>
			</StyledNavigationControlContainer>

			<Source {...osm.source}>
				<Layer {...osm.layer} />
			</Source>

			{/* Order does not matter, because only one satellite layer is visible */}
			{Object.entries(satelliteLayers).map(([key, { source, layer }]) => (
				<Source key={key} {...source}>
					<Layer
						{...layer}
						layout={{ visibility: satelliteLayer === key ? "visible" : "none" }}
					/>
				</Source>
			))}

			{/* Temporary raster quarantines */}
			<Source {...quarantine.source}>
				<Layer {...quarantine.layer} />
			</Source>

			{hives
				// Rendezzük, hogy a kijelelölt kaptár látszódjon felül
				?.sort((hive) => (hive.isInFocus ? 1 : -1))
				.map(({ id, geojson, isInFocus }) => {
					const {
						geometry: {
							coordinates: [lng, lat],
						},
					} = turf.center(geojson)
					return (
						<Fragment key={id}>
							<HiveMarker
								isInFocus={isInFocus}
								lng={lng}
								lat={lat}
								onClick={createClickHandler(geojson, [lng, lat])}
							/>
							<Source type="geojson" data={geojson}>
								<Layer
									id={`${id.toString()}-line`}
									type="line"
									paint={{
										"line-color": isInFocus
											? theme.palette.info.main
											: theme.palette.grey[500],
									}}
								/>
							</Source>
						</Fragment>
					)
				})}

			{quarantines?.map(({ id, geojson }) => (
				<Source key={id} type="geojson" data={geojson as GeoJSON.Feature}>
					<Layer
						id={id.toString()}
						type="fill"
						paint={{
							"fill-color": theme.palette.error.main,
							"fill-opacity": 0.2,
						}}
					/>
				</Source>
			))}

			{displayLegends && (
				<StyledLogoContainer>
					<BeeBoxLogoIcon
						color={satelliteLayer !== "none" ? "white" : undefined}
					/>
				</StyledLogoContainer>
			)}
		</InteractiveMap>
	)
}

export default Map
