import defaultTo from "lodash/defaultTo"

export type Bbox = {
	minlng: number
	minlat: number
	maxlng: number
	maxlat: number
}

export type LngLat = {
	lng: number
	lat: number
}

const defaultBbox = {
	minlng: -180,
	minlat: -180,
	maxlng: 180,
	maxlat: 180,
}

function setBbox(prevBbox: Partial<Bbox>, { lng, lat }: LngLat): Partial<Bbox> {
	const { minlng, minlat, maxlng, maxlat } = prevBbox
	const nextMinlng = defaultTo(
		minlng != null && lng < minlng ? lng : minlng,
		lng,
	)
	const nextMinlat = defaultTo(
		minlat != null && lat < minlat ? lat : minlat,
		lat,
	)
	const nextMaxlng = defaultTo(
		maxlng != null && lng > maxlng ? lng : maxlng,
		lng,
	)
	const nextMaxlat = defaultTo(
		maxlat != null && lat > maxlat ? lat : maxlat,
		lat,
	)
	return {
		minlng: nextMinlng,
		minlat: nextMinlat,
		maxlng: nextMaxlng,
		maxlat: nextMaxlat,
	}
}

export function bboxFromCoordinates(
	coordinates: Array<[longitude: number, latitude: number]>,
): Bbox {
	const bbox = coordinates.reduce<Partial<Bbox>>(
		(acc, [lng, lat]) => setBbox(acc, { lng, lat }),
		{},
	)
	return {
		...defaultBbox,
		...bbox,
	}
}

export function bboxFromPolygons(
	features: Array<GeoJSON.Feature<GeoJSON.Polygon>>,
): Bbox {
	const bbox = features.reduce<Partial<Bbox>>((acc, polygon) => {
		polygon.geometry.coordinates.forEach((positions) =>
			positions.forEach(([lng, lat]) => {
				// Param reassign is okay inside reduce
				// eslint-disable-next-line no-param-reassign
				acc = setBbox(acc, { lng, lat })
			}),
		)
		return acc
	}, {})
	return {
		...defaultBbox,
		...bbox,
	}
}
