import { TextField } from "@material-ui/core"
import { useUpdateHiveMutation } from "@space-apps/beebox-api-client"
import { HivePictogram } from "@space-apps/honeycomb"
import { parseISO } from "date-fns"
import { Field } from "formik"
import React, { useMemo } from "react"
import { useToggle } from "../hooks/useToggle"
import type { MinimumRequiredHiveData } from "../types/temporary-types"
import sensor from "../utils/sensor"
import FormDialog from "./FormDialog"

type HivePictogramContainerProps = {
	hive: MinimumRequiredHiveData
	editable?: boolean
}

const HivePictogramContainer: React.FC<HivePictogramContainerProps> = ({
	hive,
	editable = false,
}) => {
	const { sensors } = hive

	const temperatureDevice = useMemo(() => {
		return hive.devices.find(
			(item) =>
				item.sensors.find((sensorInHive) =>
					sensorInHive.includes("temperature_0"),
				) !== undefined,
		)
	}, [hive])

	const scale = sensor.findOneByKey(sensors, "weight")
	/* We need inner temp and humidity
	   These sensors measure outer values
	const temp = sensors.findOneByKey(sensors, "temp_load")
	const humidity = sensors.findOneByKey(sensors, "humidity")
	*/
	const gps = sensor.findOneByKey(sensors, "gps")
	const mic = sensor.findOneByKey(sensors, "mic")
	const camera = sensor.findOneByKey(sensors, "camera")
	const secondCamera = sensor.findOneByKey(sensors, "video")
	const secondMicrophone = sensor.findOneByKey(sensors, "rec")
	const battery = sensor.findOneByKey(sensors, "battery")
	const innerTempBattery = sensors.find(
		(value) =>
			value.deviceId === temperatureDevice?.id && value.key === "battery",
	)

	const saIds = hive.devices
		.map((device) => device.properties.saId)
		.filter((saId): saId is string => saId !== undefined)

	const [open, { toggleOn: handleOpen, toggleOff: handleClose }] = useToggle(
		false,
	)
	const updateHiveNoteMutation = useUpdateHiveMutation()

	return (
		<>
			<HivePictogram
				style={{ height: "100%" }}
				hiveName={hive.name}
				onEdit={editable ? handleOpen : undefined}
				scale={scale && sensor.getStatus(scale)}
				/* 
					humidity={humidity && getStatus(humidity)}
					temperature={temperature && getStatus(temp)} 
					*/
				gps={gps && sensor.getStatus(gps)}
				microphone={mic && sensor.getStatus(mic)}
				camera={camera && sensor.getStatus(camera)}
				secondCamera={secondCamera && sensor.getStatus(secondCamera)}
				secondMicrophone={
					secondMicrophone && sensor.getStatus(secondMicrophone)
				}
				innerDeviceBattery={
					innerTempBattery && sensor.getStatus(innerTempBattery)
				}
				hiveFlags={hive.flags.map(({ key }) => key)}
				scaleBatteryCharge={battery?.measurement?.value as number}
				saIds={saIds}
				lastWeightValue={
					scale?.measurement
						? {
								value: scale.measurement.value as number,
								date: parseISO(scale.measurement.absoluteTime),
						  }
						: undefined
				}
			/>
			{editable && (
				<FormDialog
					mutationDialogProps={{
						dialog: { open, onClose: handleClose },
						result: updateHiveNoteMutation,
					}}
					formik={{
						enableReinitialize: true,
						isInitialValid: false,
						initialValues: {
							name: hive.name,
						},
						onSubmit: async ({ name }) =>
							updateHiveNoteMutation.mutateAsync({ id: hive.id, name }),
						fields: (
							<Field
								as={TextField}
								name="name"
								required
								label="Név"
								variant="outlined"
							/>
						),
					}}
				/>
			)}
		</>
	)
}

export default HivePictogramContainer
