import { Grid } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import React from "react"
import styled from "styled-components"
import format from "../utils/format"
import type { NormalizedRecording } from "./AnalyticsAndMediaContainer"

const DEVELOPMENT = process.env.NODE_ENV === "development"

type MediaPlayerProps = {
	recording?: NormalizedRecording
}

function extendSrc(src: string): string {
	if (DEVELOPMENT) {
		return `https://bee-box.io${src}`
	}
	return src
}

const ContainerGrid = styled(Grid)`
	height: 100%;
	overflow: hidden;
`

const StyledImage = styled.img`
	object-fit: scale-down;
`

const StyledVideo = styled.video`
	width: 100%;
	height: 100%;
`

const StyledAudio = styled.audio`
	width: 100%;
	border-radius: 15px;
`

const MediaPlayer: React.FC<MediaPlayerProps> = ({ recording }) => {
	if (!recording) {
		return (
			<Alert variant="outlined" severity="warning">
				Nincs felvétel
			</Alert>
		)
	}
	return (
		<Grid container spacing={1} justify="space-between">
			{recording.pic && (
				<ContainerGrid
					item
					container
					justify="center"
					xs={12}
					md={!(recording.video?.value != null) ? 12 : 6}
				>
					<StyledImage
						src={extendSrc(recording.pic.value)}
						key={recording.pic.date.toString()}
						alt={`Kép ${format.dateTime(new Date(recording.pic.date))}`}
					/>
				</ContainerGrid>
			)}
			{recording.video?.value != null && (
				<ContainerGrid item xs={12} md={!recording.pic ? 12 : 6}>
					{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
					<StyledVideo loop controls key={recording.video.date.toString()}>
						<source src={extendSrc(recording.video.value)} type="video/mp4" />
						<span>A böngésző nem támogatja a videó lejátszást.</span>
					</StyledVideo>
				</ContainerGrid>
			)}
			{recording.rec && (
				<Grid item xs={12}>
					{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
					<StyledAudio controls key={recording.rec.date.toString()}>
						<source src={extendSrc(recording.rec.value)} type="audio/mpeg" />
						<span>A böngésző nem támogatja a hang lejátszást.</span>
					</StyledAudio>
				</Grid>
			)}
		</Grid>
	)
}

export default MediaPlayer
