import React from "react"
import * as yup from "yup"
import { useSwapQueenMutation } from "../hooks/hiveInspectionSheet/useSwapQueenMutation"
import type { HiveInspectionSheetQueen } from "../utils/hiveInspectionSheet"
import FormDialog from "./FormDialog"
import { HiveQueenFields } from "./HiveInspectionSheetFields"

export type HiveQueenSwapDialogProps = {
	hiveId: number
	open: boolean
	onClose: () => void
	initialValues?: HiveInspectionSheetQueen
}

const validationSchema = yup.object().shape({
	mark: yup.string().required(),
	number: yup.string().required(),
	swapDate: yup.date(),
	swapReason: yup.string().required(),
	morphologyColor: yup.string(),
	morphologySucc: yup.string(),
})

const defaultInitialValues: Omit<HiveInspectionSheetQueen, "id"> = {
	number: "",
	mark: "",
	swapDate: new Date(),
	swapReason: undefined,
	morphologyColor: undefined,
	morphologySucc: undefined,
}

export const HiveQueenSwapDialog: React.FC<HiveQueenSwapDialogProps> = ({
	hiveId,
	open,
	onClose,
	initialValues,
}) => {
	const swapQueenMutation = useSwapQueenMutation({ hiveId })
	return (
		<FormDialog
			formik={{
				initialValues: initialValues ?? defaultInitialValues,
				onSubmit: async (values) => {
					return swapQueenMutation.mutateAsync({ queen: values })
				},
				validateOnChange: true,
				validationSchema,
				isInitialValid: !initialValues,
				fields: <HiveQueenFields />,
			}}
			mutationDialogProps={{
				result: swapQueenMutation,
				dialog: {
					open,
					onClose,
					components: {
						title: "Anyacsere",
					},
				},
			}}
		/>
	)
}
