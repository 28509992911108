import { CircularProgress, Grid } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useDeviceQuery } from "@space-apps/beebox-api-client"
import React from "react"
import type { RouteComponentProps } from "react-router-dom"
import DeviceActionsButtons from "../components/DeviceActionsButtons"
import DeviceInfo from "../components/DeviceInfo"
import SensorDebug from "../components/SensorDebug"
import PageWithContainer from "./PageWithContainer"

type MatchParams = {
	deviceId: string
}

export type DeviceDebugPageProps = RouteComponentProps<MatchParams>

const DeviceDebugPage: React.FC<DeviceDebugPageProps> = ({ match }) => {
	const { isLoading, data: device, error } = useDeviceQuery(
		{
			id: Number(match.params.deviceId),
		},
		{ refetchOnWindowFocus: false },
	)

	if (isLoading) {
		return (
			<Grid container justify="center" alignItems="center">
				<CircularProgress />
			</Grid>
		)
	}

	if (error != null || device == null) {
		return (
			<PageWithContainer>
				<Grid container justify="center" alignItems="center">
					{error != null && (
						<Alert severity="error" variant="outlined">
							{(error as Error).message}
						</Alert>
					)}
					{device == null && (
						<Alert severity="error" variant="outlined">
							&quot;{match.params.deviceId}&quot; azonosítójú eszköz nem
							létezik!
						</Alert>
					)}
				</Grid>
			</PageWithContainer>
		)
	}
	return (
		<PageWithContainer title={`Eszköz ID: ${device.id} részletei`}>
			<DeviceInfo device={device} />
			<DeviceActionsButtons
				deviceStatus={device.status}
				deviceSerial={device.serialNumber}
			/>
			<SensorDebug sensors={device.sensors} />
		</PageWithContainer>
	)
}

export default DeviceDebugPage
