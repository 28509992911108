import type { ButtonProps, DialogProps } from "@material-ui/core"
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	useMediaQuery,
	useTheme,
} from "@material-ui/core"
import type { TransitionProps } from "@material-ui/core/transitions"
import Alert from "@material-ui/lab/Alert"
import React, { useCallback, useEffect } from "react"
import type { UseMutationResult } from "react-query"

const Transition = React.forwardRef(function Transition(
	props: TransitionProps,
	ref: React.Ref<unknown>,
) {
	return <Slide direction="up" ref={ref} {...props} />
})

export type MutationDialogProps<
	TData,
	TError,
	TVariables,
	TContext
> = React.PropsWithChildren<{
	result: UseMutationResult<TData, TError, TVariables, TContext>
	dialog: DialogProps & {
		onClose: () => void
	} & {
		components?: {
			title?: React.ReactNode
			contentText?: React.ReactNode
			cancelButtonText?: React.ReactNode
			confirmButtonText?: React.ReactNode
			confirmButtonProps?: (
				result: UseMutationResult<TData, TError, TVariables, TContext>,
			) => ButtonProps
		}
	}
	onSuccess?: (result: TData | undefined, close: () => void) => void
}>

function MutationDialog<TData, TError, TVariables, TContext>({
	dialog,
	result,
	children,
	onSuccess: handleSuccess,
}: MutationDialogProps<
	TData,
	TError,
	TVariables,
	TContext
>): React.ReactElement {
	const {
		components: {
			title,
			contentText,
			cancelButtonText = "Mégse",
			confirmButtonText = "OK",
			confirmButtonProps,
		} = {},
		onClose: handleClose,
		...dialogProps
	} = dialog

	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

	const { isLoading, error, isSuccess, data, reset } = result

	const handleCloseAndResetMutationState = useCallback(() => {
		reset()
		handleClose()
	}, [reset, handleClose])

	useEffect(() => {
		if (isSuccess) {
			if (handleSuccess) {
				handleSuccess(data, handleCloseAndResetMutationState)
			} else {
				handleCloseAndResetMutationState()
			}
			reset()
		}
	}, [isSuccess, data, handleSuccess, handleCloseAndResetMutationState, reset])

	return (
		<Dialog
			fullScreen={fullScreen}
			TransitionComponent={Transition}
			onClose={handleCloseAndResetMutationState}
			{...dialogProps}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{contentText}</DialogContentText>
				{error != null && (
					<Alert severity="error" variant="filled">
						{((error as unknown) as Error).message}
					</Alert>
				)}
				{children}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					{cancelButtonText}
				</Button>
				<Button
					color="primary"
					variant="contained"
					disabled={isLoading}
					{...confirmButtonProps?.(result)}
				>
					{isLoading && <CircularProgress />}
					{confirmButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default MutationDialog
