import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"
import { DatePicker } from "@material-ui/pickers"
import React, { useCallback, useEffect, useRef, useState } from "react"
import type { TimeOfDay } from "./Camera"

const TIME_OF_DAY_LIST: TimeOfDay[] = [
	"00:00-05:59",
	"06:00-11:59",
	"12:00-17:59",
	"18:00-23:59",
]

export interface CameraSensorQueryInput {
	day: Date
	tod: TimeOfDay
}

const CameraSensorQueryInputGroup: React.FC<{
	onChange: (value: CameraSensorQueryInput) => void
	disabled?: boolean
}> = ({ onChange, disabled = false }) => {
	const [input, setInput] = useState<CameraSensorQueryInput>({
		day: new Date(),
		tod: TIME_OF_DAY_LIST[0],
	})

	const savedCallback = useRef(onChange)
	useEffect(() => {
		savedCallback.current = onChange
	}, [onChange])
	useEffect(() => {
		savedCallback.current(input)
	}, [input])

	const handleDayChange = useCallback((date: Date | null) => {
		setInput((i) => ({ ...i, day: date ?? new Date() }))
	}, [])

	const handleTimeOfDayChange = useCallback(
		(event: React.ChangeEvent<{ value: unknown }>) => {
			setInput((i) => ({ ...i, tod: event.target.value as TimeOfDay }))
		},
		[],
	)

	return (
		<Grid
			container
			direction="column"
			justify="flex-start"
			alignItems="stretch"
			spacing={1}
		>
			<Grid item>
				<Grid container justify="space-between" alignItems="center">
					<Grid item>
						<Typography component="label" htmlFor="day">
							Nap:
						</Typography>
					</Grid>
					<Grid item>
						<DatePicker
							id="day"
							value={input.day}
							onChange={handleDayChange}
							autoOk
							disableFuture
							format="yyyy-MM-dd"
							disabled={disabled}
						/>
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<Grid container justify="space-between" alignItems="center">
					<Grid item>
						<Typography component="label" htmlFor="tod">
							Napszak:
						</Typography>
					</Grid>
					<Grid item>
						<Select
							id="tod"
							value={input.tod}
							onChange={handleTimeOfDayChange}
							disabled={disabled}
						>
							{TIME_OF_DAY_LIST.map((tod) => (
								<MenuItem key={tod} value={tod}>
									{tod}
								</MenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default CameraSensorQueryInputGroup
