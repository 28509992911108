/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useSetHiveInspectionSheetMutation } from "@space-apps/beebox-api-client"
import { useCallback } from "react"
import { useMutation } from "react-query"
import * as hiveInspectionSheet from "../../utils/hiveInspectionSheet"

export function useCreateQueenMutation() {
	const setHiveInspectionSheetMutation = useSetHiveInspectionSheetMutation()
	const createQueen = useCallback(
		async ({
			hiveId,
			queen,
		}: {
			hiveId: number
			queen: Omit<hiveInspectionSheet.HiveInspectionSheetQueen, "id">
		}) =>
			setHiveInspectionSheetMutation.mutateAsync({
				hiveId,
				inspectionSheet: hiveInspectionSheet.toString(
					hiveInspectionSheet.createQueen({ queen, hiveId }),
				),
			}),
		[setHiveInspectionSheetMutation],
	)
	return useMutation(createQueen)
}
