import { useTheme } from "@material-ui/core"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import React from "react"
import { Marker as RMGLMarker } from "react-map-gl"
import styled from "styled-components"

export type HiveMarkerProps = {
	isInFocus: boolean
	lat: number
	lng: number
	onClick?: () => void
}

const MarkerButton = styled.button`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`
export const MARKER_SIZE_IN_PIXEL = 24

const StyledMarkerIcon = styled(FiberManualRecordIcon)`
	width: ${MARKER_SIZE_IN_PIXEL}px;
	height: ${MARKER_SIZE_IN_PIXEL}px;
	transform: translate(
		-${MARKER_SIZE_IN_PIXEL / 2}px,
		-${MARKER_SIZE_IN_PIXEL / 2}px
	);
`

export const HiveMarker: React.FC<HiveMarkerProps> = ({
	isInFocus = true,
	onClick,
	lat,
	lng,
}) => {
	const theme = useTheme()
	return (
		<RMGLMarker latitude={lat} longitude={lng}>
			<MarkerButton aria-label="marker" onClick={onClick}>
				<StyledMarkerIcon
					htmlColor={
						isInFocus ? theme.palette.info.main : theme.palette.grey[500]
					}
				/>
			</MarkerButton>
		</RMGLMarker>
	)
}
