import { Typography } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import {
	useHiveGroupListQuery,
	useHiveListQuery,
} from "@space-apps/beebox-api-client"
import React from "react"
import styled from "styled-components"
import HiveGroupInfo from "../components/HiveGroupInfo"
import HiveGroupListChart from "../components/HiveGroupListChart"
import HiveGroupListMap from "../components/HiveGroupListMap"
import HiveGroupListMeasurementsCard from "../components/HiveGroupListMeasurementsCard"
import MeasurementsCardAndChartGrid from "../components/MeasurementsCardAndChartGrid"
import { useMe } from "../hooks/useMe"
import PageWithContainer from "./PageWithContainer"
import PageWithMap from "./PageWithMap"

const StyledTitle = styled(Typography)`
	margin-top: 1em;
	margin-bottom: 1em;
	font-weight: 500;
`

const HiveGroupListPage: React.FC = () => {
	const { data: me } = useMe()
	// TODO: Handle more than 100 HiveGroups
	const { isLoading, data } = useHiveGroupListQuery({})
	const { isLoading: hivesLoading, data: hivesData } = useHiveListQuery({})

	if (isLoading || !data) {
		return (
			<PageWithContainer>
				<CircularProgress />
			</PageWithContainer>
		)
	}

	const { data: hiveGroups } = data
	return (
		<PageWithMap
			title={me ? `${me.name} Méhészet` : undefined}
			map={<HiveGroupListMap fixInitialBound />}
		>
			<Grid container direction="column" spacing={4}>
				<Grid container item xs={12} spacing={2}>
					<StyledTitle variant="h5">Áttekintés</StyledTitle>
					{hivesLoading && <CircularProgress />}
					{hivesData && (
						<MeasurementsCardAndChartGrid
							measurementsCard={
								<HiveGroupListMeasurementsCard hiveGroups={hiveGroups} />
							}
							chart={
								<HiveGroupListChart
									hiveGroups={hiveGroups}
									hives={hivesData.data}
								/>
							}
						/>
					)}
				</Grid>

				<Grid container item direction="column" xs={12}>
					<StyledTitle variant="h5">Méhesek</StyledTitle>
					<Grid container direction="column" spacing={2}>
						{hiveGroups.map((hiveGroup) => (
							<Grid key={hiveGroup.id} item xs={12}>
								<HiveGroupInfo hiveGroup={hiveGroup} />
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</PageWithMap>
	)
}

export default HiveGroupListPage
