import { Button, CircularProgress, Grid } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import React from "react"
import type { RouteComponentProps } from "react-router-dom"
import styled, { css } from "styled-components"
import { HiveInspectionSheetFormDialog } from "../components/HiveInspectionSheetFormDialog"
import HiveInspectionSheetTable from "../components/HiveInspectionSheetTable"
import HiveQueenInfo from "../components/HiveQueenInfo"
import { useHiveInspectionSheetQuery } from "../hooks/hiveInspectionSheet/useHiveInspectionSheetQuery"
import { useToggle } from "../hooks/useToggle"
import Page from "./Page"
import PageWithContainer from "./PageWithContainer"

const StyledRootGrid = styled(Grid)(
	({ theme }) => css`
		padding: ${theme.spacing(1)}px;
		flex-direction: column;
		flex-wrap: nowrap;
	`,
)

const TableGrid = styled(Grid)(
	() => css`
		flex-basis: 0;
	`,
)

type MatchParams = {
	hiveId: string
}

export type HiveInspectionSheetPageProps = RouteComponentProps<MatchParams>

const HiveInspectionSheetPage: React.FC<HiveInspectionSheetPageProps> = ({
	match,
}) => {
	const [open, { toggleOff, toggleOn }] = useToggle(false)
	const {
		data: hiveInspectionSheet,
		error,
		isLoading,
	} = useHiveInspectionSheetQuery(
		{
			hiveId: Number(match.params.hiveId),
		},
		{ retry: false },
	)

	if (error != null) {
		return (
			<PageWithContainer>
				<Grid container justify="center" alignItems="center">
					<Alert severity="error" variant="outlined">
						{error.message}
					</Alert>
				</Grid>
			</PageWithContainer>
		)
	}

	return (
		<Page title="Kaptárlap">
			{isLoading ? (
				<CircularProgress />
			) : (
				<StyledRootGrid container direction="column" spacing={2}>
					<Grid item xs={12}>
						<HiveQueenInfo
							hiveInspectionSheet={hiveInspectionSheet}
							hiveId={Number(match.params.hiveId)}
						/>
					</Grid>
					{hiveInspectionSheet && (
						<>
							<Grid item xs={12} container>
								<Grid container justify="flex-end">
									<Button
										color="primary"
										variant="contained"
										onClick={toggleOn}
									>
										Hozzáadás
									</Button>
								</Grid>
							</Grid>
							<TableGrid item xs={12}>
								<HiveInspectionSheetTable
									hiveInspectionSheet={hiveInspectionSheet}
								/>
							</TableGrid>

							<HiveInspectionSheetFormDialog
								open={open}
								onClose={toggleOff}
								hiveId={hiveInspectionSheet.hiveId}
								queenId={hiveInspectionSheet.queen.id}
							/>
						</>
					)}
				</StyledRootGrid>
			)}
		</Page>
	)
}

export default HiveInspectionSheetPage
