import React, { useMemo } from "react"
import type { ProfileRow } from "../components/Profile"
import Profile from "../components/Profile"
import { useMe } from "../hooks/useMe"
import PageWithContainer from "./PageWithContainer"

const ProfilePage: React.FC = () => {
	const { data: me } = useMe()
	const rows: ProfileRow[] = useMemo(() => {
		if (me) {
			const {
				name,
				email,
				phone_number_primary: phoneNumberPrimary,
				address,
				billing_address: billingAddress,
				is_subscribed: isSubscribed,
				is_gdpr_approved: isGDPRApproved,
			} = me
			return [
				{ label: "Név", value: name },
				{ label: "Email", value: email },
				{ label: "Telefonszám", value: phoneNumberPrimary ?? "?" },
				{ label: "Cím", value: address ?? "?" },
				{ label: "Számlázási cím", value: billingAddress ?? "?" },
				{
					label: "Előfizető",
					value: isSubscribed != null && isSubscribed ? "Igen" : "Nem",
				},
				{
					label: "GDPR elfogadva",
					value: isGDPRApproved != null && isGDPRApproved ? "Igen" : "Nem",
				},
			]
		}
		return []
	}, [me])
	return (
		<PageWithContainer title="Profilom">
			<Profile rows={rows} />
		</PageWithContainer>
	)
}

export default ProfilePage
