import { useRef, useState } from "react"

export function useToggle(
	defaultState = false,
): [
	boolean,
	{ toggleOn: () => void; toggleOff: () => void; toggle: () => void },
] {
	const [state, setState] = useState(defaultState)
	const toggleOn = useRef(() => setState(true))
	const toggleOff = useRef(() => setState(false))
	const toggle = useRef(() => setState((s) => !s))
	return [
		state,
		{
			toggleOn: toggleOn.current,
			toggleOff: toggleOff.current,
			toggle: toggle.current,
		},
	]
}
