/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as hiveInspectionSheet from "../../utils/hiveInspectionSheet"
import { useSetHiveInspectionSheetWrapperMutation } from "./useSetHiveInspectionSheetWrapperMutation"

export function useUpdateQueenMutation({ hiveId }: { hiveId: number }) {
	return useSetHiveInspectionSheetWrapperMutation(
		hiveId,
		hiveInspectionSheet.updateQueen,
	)
}
