import Button from "@material-ui/core/Button"
import type { FieldProps } from "formik"
import { isString } from "lodash"
import React, { useCallback, useState } from "react"
import type { DropzoneOptions } from "react-dropzone"
import { useDropzone } from "react-dropzone"
import { StyledThumbnailsContainer } from "./DropzoneField"
import DropzoneThumbnail from "./DropzoneThumbnail"

export type HiveNoteImageFieldProps<FormValues> = FieldProps<
	File | null | undefined,
	FormValues
> & { currentImageSource?: string }

/**
 * Copy of `DropzoneField` component.
 *
 * I could not figure out how to implement arbitrary editable file arrays yet,
 * so this component will be temporarily used for hiveNote image input.
 *
 * Cases:
 * if `field.value` is a `File` then upload new file, potentially overriding current image
 * if `field.value` is `null` then current image will be deleted
 * if `field.value` is `undefined` then no file is uploaded, current image is retained if exists
 */
function HiveNoteImageField<FormValues>({
	field,
	form,
	currentImageSource,
}: HiveNoteImageFieldProps<FormValues>): React.ReactElement {
	const handleDrop = useCallback<Required<DropzoneOptions>["onDrop"]>(
		(acceptedFiles) => {
			form.setFieldValue(field.name, acceptedFiles[0])
		},
		[field.name, form],
	)
	const { getRootProps, getInputProps } = useDropzone({
		multiple: false,
		onDrop: handleDrop,
		accept: "image/jpeg, image/png",
		noDrag: true,
	})

	const [isCurrentImageCleared, setIsCurrentImagedCleared] = useState(false)
	const handleFileRemove = useCallback(() => {
		form.setFieldValue(field.name, isCurrentImageCleared ? null : undefined)
	}, [field.name, isCurrentImageCleared, form])
	const handleCurrentImageRemove = useCallback(() => {
		form.setFieldValue(field.name, null)
		setIsCurrentImagedCleared(true)
	}, [field.name, form])

	const { value: file } = field

	return (
		<div>
			<input {...getInputProps()} />
			<Button {...getRootProps()} variant="outlined" color="primary">
				Tallózás
			</Button>
			<StyledThumbnailsContainer>
				{currentImageSource != null &&
					!isCurrentImageCleared &&
					file == null && (
						<DropzoneThumbnail
							onRemove={handleCurrentImageRemove}
							src={currentImageSource}
							alt={currentImageSource}
						/>
					)}
				{file != null && !isString(file) && (
					<DropzoneThumbnail
						onRemove={handleFileRemove}
						// Caution: URL API not supported in IE, see: https://caniuse.com/?search=url
						src={URL.createObjectURL(file)}
						alt={file.name}
					/>
				)}
			</StyledThumbnailsContainer>
		</div>
	)
}

export default HiveNoteImageField
