import type {
	Measurements,
	MeasurementValue,
	SensorMeasurementsSeriesVariables,
} from "@space-apps/beebox-api-client"
import { useSensorMeasurementsSeriesQuery } from "@space-apps/beebox-api-client"
import type { UseQueryOptions, UseQueryResult } from "react-query"

/**
 * Accepts missing `variables.deviceId` to enable easier optional Bee Activity queries.
 * If `variables.deviceId` is missing, the query is disabled and won't run.
 */
export function useOptionalSensorMeasurementsSeriesQuery<
	M extends MeasurementValue
>(
	variables: PartialBy<SensorMeasurementsSeriesVariables, "deviceId">,
	config?: UseQueryOptions<Measurements<M>>,
): UseQueryResult<Measurements<M>> {
	// @ts-expect-error: Passing partial `variables` is safe, because query is disabled if it's incomplete.
	return useSensorMeasurementsSeriesQuery<M>(variables, {
		...config,
		enabled: variables.deviceId != null,
	})
}
