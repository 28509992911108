import { Link } from "react-router-dom"
import styled from "styled-components"

// Link component with removed underline style
// For wrap components, which needs navigation

const WrapperLink = styled(Link)`
	text-decoration: none;

	&:focus,
	&:hover,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
		color: inherit;
	}
`

export default WrapperLink
