import { Grid, Paper } from "@material-ui/core"
import React from "react"
import LoginForm from "../components/LoginForm"

const LoginPage: React.FC = () => {
	return (
		<Grid
			container
			justify="center"
			alignItems="center"
			style={{ height: "100%" }}
		>
			<Grid item>
				<Paper style={{ padding: 16 }}>
					<LoginForm />
				</Paper>
			</Grid>
		</Grid>
	)
}

export default LoginPage
