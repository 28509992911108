import type { Hive } from "@space-apps/beebox-api-client"
import React, { useCallback } from "react"
import MapContainer from "./MapContainer"

export type HiveGroupMapProps = {
	id: number
	fixInitialBound?: boolean
}

const HiveGroupMap: React.FC<HiveGroupMapProps> = ({ id, fixInitialBound }) => {
	const filterByHiveGroupId = useCallback(
		(hive: Hive) => hive.hiveGroupId === id,
		[id],
	)
	return (
		<MapContainer
			predicate={filterByHiveGroupId}
			fixInitialBound={fixInitialBound}
		/>
	)
}

export default HiveGroupMap
