import Paper from "@material-ui/core/Paper"
import type { FlagKey } from "@space-apps/honeycomb"
import { FlagChip, FLAGS } from "@space-apps/honeycomb"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import styled, { css } from "styled-components"

export type FlagInputValue = FlagKey[] | null | undefined

export type FlagInputProps = {
	multiple?: boolean
	value: FlagInputValue
	onChange: (value: FlagInputValue) => void
}

const StyledPaper = styled(Paper)(
	({ theme }) => css`
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		list-style: none;
		padding: ${theme.spacing(0.5)}px;
		margin: 0;
	`,
)

const FlagInput: React.FC<FlagInputProps> = ({
	value,
	onChange,
	multiple = false,
}) => {
	const savedChangeHandler = useRef(onChange)
	useEffect(() => {
		savedChangeHandler.current = onChange
	}, [onChange])

	const isInitializedWithValue = useRef(
		Array.isArray(value) && value.length > 0,
	)

	const clicked = useMemo(
		() =>
			(value ?? []).reduce<{ [key: string]: true | undefined }>((acc, key) => {
				acc[key] = true
				return acc
			}, {}),
		[value],
	)
	const createClickHandler = useCallback(
		(clickedFlag: FlagKey) => () => {
			const isTagInClicked = clicked[clickedFlag] ?? false

			if (value) {
				if (
					isInitializedWithValue.current &&
					isTagInClicked &&
					value.length === 1
				) {
					return savedChangeHandler.current(null)
				}
				if (multiple) {
					const clickedFlags = isTagInClicked
						? value.filter((flag) => clickedFlag !== flag)
						: [...value, clickedFlag]
					return savedChangeHandler.current(clickedFlags)
				}
			}

			return savedChangeHandler.current(isTagInClicked ? [] : [clickedFlag])
		},
		[value, clicked, multiple],
	)

	return (
		<StyledPaper component="ul">
			{(Object.keys(FLAGS) as FlagKey[]).map((flag) => (
				<li key={flag}>
					<FlagChip
						id={flag}
						active={clicked[flag]}
						chipProps={{
							onClick: createClickHandler(flag),
						}}
					/>
				</li>
			))}
		</StyledPaper>
	)
}

export default FlagInput
