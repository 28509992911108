import DateFnsUtils from "@date-io/date-fns"
import CircularProgress from "@material-ui/core/CircularProgress"
import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import {
	BeeBoxApiClientProvider,
	RememberMe,
} from "@space-apps/beebox-api-client"
import { ThemeProvider } from "@space-apps/honeycomb"
import React from "react"
import { hotjar } from "react-hotjar"
import { HashRouter as Router } from "react-router-dom"
import { IntervalContextProvider } from "./context"
import "./index.css"
import "./lib/axios"
import Routes from "./Routes"

const { REACT_APP_ENABLE_ANALYTICS, NODE_ENV } = process.env
if (NODE_ENV === "production" && REACT_APP_ENABLE_ANALYTICS === "true") {
	hotjar.initialize(2082115, 6)
}

const App: React.FC = () => {
	return (
		<>
			<CssBaseline />
			<ThemeProvider>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<BeeBoxApiClientProvider baseUrl={process.env.REACT_APP_API_ENDPOINT}>
						<Router>
							<RememberMe fallback={<CircularProgress />}>
								<IntervalContextProvider>
									<Routes />
								</IntervalContextProvider>
							</RememberMe>
						</Router>
					</BeeBoxApiClientProvider>
				</MuiPickersUtilsProvider>
			</ThemeProvider>
		</>
	)
}

export default App
