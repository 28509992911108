import { CircularProgress } from "@material-ui/core"
import { useSensorMeasurements24hChangesQuery } from "@space-apps/beebox-api-client"
import type {
	HiveGroup,
	Sensor,
} from "@space-apps/beebox-api-client/dist/client"
import { BeeBoxMeasurementsCard, Debug } from "@space-apps/honeycomb"
import React from "react"
import { useMe } from "../hooks/useMe"
import format from "../utils/format"
import math from "../utils/math"
import sensorUtil from "../utils/sensor"

export type HiveGroupListMeasurementsCardProps = {
	hiveGroups: HiveGroup[]
}

function extractWeightSensorQueryParams(
	hiveGroups: HiveGroup[],
): Array<{ deviceId: number; sensorKey: "weight" }> {
	return hiveGroups
		.flatMap((hiveGroup) => hiveGroup.devices)
		.filter((device) => device.sensors.find((sensor) => /weight/.exec(sensor)))
		.map((device) => ({ deviceId: device.id, sensorKey: "weight" }))
}

const HiveGroupListMeasurementsCard: React.FC<HiveGroupListMeasurementsCardProps> = ({
	hiveGroups,
}) => {
	const { data: me } = useMe()

	const weightSensorParams = extractWeightSensorQueryParams(hiveGroups)
	const {
		isLoading,
		data: changes,
		error,
	} = useSensorMeasurements24hChangesQuery(weightSensorParams, {
		enabled: weightSensorParams.length > 0,
	})

	if (isLoading || !changes) {
		return <CircularProgress />
	}

	if (error != null) {
		return <Debug value={error} />
	}

	const weightSensors = hiveGroups
		.flatMap((hiveGroup) => hiveGroup.sensors)
		.filter((sensor) => sensor.key === "weight") as Array<Sensor<number>>

	// These will be needed for future tooltips.
	// const numOfHives = hiveGroups.reduce(
	// 	(acc, hiveGroup) => hiveGroup.hives.length + acc,
	// 	0,
	// )
	// const numOfHivesWithoutWeightSensor = numOfHives - weightSensors.length

	const sum = sensorUtil.sum(weightSensors)
	const avg = sensorUtil.avg(weightSensors)
	const changeSum = math.sum(
		changes.filter<number>((change): change is number => change != null),
	)

	return (
		<BeeBoxMeasurementsCard
			title={me ? `${me.name} Méhészet` : "Nincs felhasználó"}
			rows={[
				{
					label: "Összesített tömeg",
					value: format.kilogram(sum),
					// TODO: tooltip
					// tooltip:
					// 	numOfHivesWithoutWeightSensor > 0
					// 		? "{numOfHivesWithoutWeightSensor} kaptárhoz nincs mérleg!"
					// 		: undefined,
				},
				{
					label: "Átlag méhes tömeg",
					value: format.kilogram(avg),
				},
				{
					label: "Tömeg változás",
					value: format.kilogramPerDay(changeSum),
					// TODO: tooltip
					// tooltip:
					// 	numOfHivesWithoutWeightSensor > 0
					// 		? "{numOfHivesWithoutWeightSensor} kaptárhoz nincs mérleg!"
					// 		: undefined,},
				},
			]}
		/>
	)
}

export default HiveGroupListMeasurementsCard
