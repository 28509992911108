import { Button, Grid, Typography } from "@material-ui/core"
import React from "react"
import { useToggle } from "../hooks/useToggle"
import format from "../utils/format"
import type { HiveInspectionSheet } from "../utils/hiveInspectionSheet"
import { HiveQueenFormDialog } from "./HiveQueenFormDialog"
import HiveQueensTable from "./HiveQueensTable"
import { HiveQueenSwapDialog } from "./HiveQueenSwapDialog"

interface HiveQueenInfoProps {
	hiveInspectionSheet?: HiveInspectionSheet
	hiveId: number
}

const MainInfo: React.FC<{ hiveInspectionSheet: HiveInspectionSheet }> = ({
	hiveInspectionSheet,
}) => (
	<Grid container direction="column">
		<Grid item>
			<Typography variant="h5">Törzskönyvi száma</Typography>
			<Typography>{hiveInspectionSheet.queen.number}</Typography>
		</Grid>
		<Grid item>
			<Typography variant="h5">Jele</Typography>
			<Typography>{hiveInspectionSheet.queen.mark}</Typography>
		</Grid>
		<Grid item>
			<Typography variant="h5">Anya csere dátum</Typography>
			<Typography>{format.date(hiveInspectionSheet.queen.swapDate)}</Typography>
		</Grid>
		<Grid item>
			<Typography variant="h5">Csere oka</Typography>
			<Typography>
				{hiveInspectionSheet.queen.swapReason ?? "Nincs megadva"}
			</Typography>
		</Grid>
	</Grid>
)

const Morphology: React.FC<{ hiveInspectionSheet: HiveInspectionSheet }> = ({
	hiveInspectionSheet,
}) => (
	<Grid container direction="column">
		<Typography variant="h5">Morfológia</Typography>
		<Grid item>
			<Typography variant="h6">Szín</Typography>
			<Typography>
				{hiveInspectionSheet.queen.morphologyColor ?? "Nincs megadva"}
			</Typography>
		</Grid>
		<Grid item>
			<Typography variant="h6">Szipóka</Typography>
			<Typography>
				{hiveInspectionSheet.queen.morphologySucc ?? "Nincs megadva"}
			</Typography>
		</Grid>
	</Grid>
)

const HiveQueenInfo: React.FC<HiveQueenInfoProps> = ({
	hiveInspectionSheet,
	hiveId,
}) => {
	const [open, { toggleOn, toggleOff }] = useToggle()
	const [
		openSwap,
		{ toggleOn: toggleSwapOn, toggleOff: toggleSwapOff },
	] = useToggle()

	const [
		openTable,
		{ toggleOn: toggleTableOn, toggleOff: toggleTableOff },
	] = useToggle()

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<Typography variant="h4">Jelenlegi anya</Typography>
			</Grid>

			{hiveInspectionSheet ? (
				<>
					<Grid item>
						<Grid container spacing={4}>
							<Grid item>
								<MainInfo hiveInspectionSheet={hiveInspectionSheet} />
							</Grid>
							<Grid item>
								<Morphology hiveInspectionSheet={hiveInspectionSheet} />
							</Grid>
						</Grid>
					</Grid>

					<Grid item>
						<Grid container spacing={2}>
							<Grid item>
								<Button
									color="primary"
									variant="contained"
									onClick={toggleSwapOn}
								>
									Anyacsere
								</Button>
							</Grid>
							<Grid item>
								<Button color="primary" variant="outlined" onClick={toggleOn}>
									Szerkesztés
								</Button>
							</Grid>
							<Grid item>
								<Button
									color="primary"
									variant="outlined"
									onClick={toggleTableOn}
								>
									Összes anya
								</Button>
							</Grid>
						</Grid>
					</Grid>

					<Grid item>
						<HiveQueensTable
							open={openTable}
							hiveInspectionSheet={hiveInspectionSheet}
							onClose={toggleTableOff}
						/>
					</Grid>
				</>
			) : (
				<Grid item xs={12}>
					<Typography>Nem található még adat!</Typography>
					<Button color="primary" variant="contained" onClick={toggleOn}>
						Kitöltés
					</Button>
				</Grid>
			)}

			<HiveQueenFormDialog
				open={open}
				onClose={toggleOff}
				initialValues={
					hiveInspectionSheet
						? hiveInspectionSheet.queens.get(hiveInspectionSheet.queenId)
						: undefined
				}
				hiveId={hiveId}
			/>
			<HiveQueenSwapDialog
				open={openSwap}
				onClose={toggleSwapOff}
				initialValues={undefined}
				hiveId={hiveId}
			/>
		</Grid>
	)
}

export default HiveQueenInfo
