import { format as formatDate } from "date-fns"

function number(value: number, options?: Intl.NumberFormatOptions): string {
	return new Intl.NumberFormat("hu-HU", options).format(value)
}

function kilogram(value: number): string {
	return number(value, {
		style: "unit",
		unit: "kilogram",
		unitDisplay: "short",
		maximumFractionDigits: 2,
	})
}

function kilogramPerDay(value: number): string {
	const formattedValue = number(value, {
		style: "unit",
		unit: "kilogram-per-day",
		maximumFractionDigits: 2,
	})
	const sign = value >= 0 ? "+" : ""
	return sign + formattedValue
}

function celsius(value: number): string {
	return number(value, {
		style: "unit",
		unit: "celsius",
		unitDisplay: "short",
		maximumFractionDigits: 1,
	})
}

function humidity(value: number): string {
	return number(value, {
		style: "percent",
	})
}

export const DATE_FORMAT = "y-MM-dd"
export const CHART_DATE_FORMAT = "yyyy LLL d"
export const TIME_FORMAT = "HH:mm"
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
export const CHART_DATE_TIME_FORMAT = `${CHART_DATE_FORMAT} ${TIME_FORMAT}`

function date(value: Date): string {
	return formatDate(value, DATE_FORMAT)
}

function dateTime(value: Date): string {
	return formatDate(value, DATE_TIME_FORMAT)
}

function time(value: Date): string {
	return formatDate(value, TIME_FORMAT)
}

const format = {
	number,
	kilogram,
	kilogramPerDay,
	celsius,
	humidity,
	date,
	dateTime,
	time,
}

export default format
