import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import type { HiveNote, HiveNoteFlag } from "@space-apps/beebox-api-client"
import {
	useCreateHiveNoteMutation,
	useDeleteHiveNoteMutation,
	useHiveNotesQuery,
	useUpdateHiveNoteMutation,
} from "@space-apps/beebox-api-client"
import { HiveNoteList } from "@space-apps/honeycomb"
import parseISO from "date-fns/parseISO"
import { Field } from "formik"
import React, { useState } from "react"
import styled from "styled-components"
import { useToggle } from "../hooks/useToggle"
import { DATE_TIME_FORMAT } from "../utils/format"
import ConfirmationDialog from "./ConfirmationDialog"
import DateTimePickerField from "./DateTimePickerField"
import FormDialog from "./FormDialog"
import HiveNoteImageField from "./HiveNoteImageField"
import HiveNoteTagField from "./HiveNoteTagField"

export type HiveNoteListContainerProps = {
	hiveId: number
	notesMaxHeight: number
}

const StyledTypography = styled(Typography)`
	font-weight: bold;
`

const HiveNoteFormFields: React.FC<{ currentImageSource?: string }> = ({
	currentImageSource,
}) => {
	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<Field
					component={DateTimePickerField}
					name="createdAt"
					disableFuture
					required
					format={DATE_TIME_FORMAT}
					fullWidth
					inputVariant="outlined"
					label="Dátum"
				/>
			</Grid>
			<Grid item>
				<Field
					as={TextField}
					name="text"
					rows={2}
					multiline
					label="Megjegyzés"
					variant="outlined"
					fullWidth
				/>
			</Grid>
			<Grid item>
				<Field component={HiveNoteTagField} name="flags" label="Címke" />
			</Grid>
			<Grid item>
				<Field
					component={HiveNoteImageField}
					name="attachment"
					currentImageSource={currentImageSource}
				/>
			</Grid>
		</Grid>
	)
}

const HiveNoteListContainer: React.FC<HiveNoteListContainerProps> = ({
	hiveId,
	notesMaxHeight,
}) => {
	const { isLoading, data } = useHiveNotesQuery({ hiveId })

	const createHiveNoteMutation = useCreateHiveNoteMutation()
	const updateHiveNoteMutation = useUpdateHiveNoteMutation()
	const deleteHiveNoteMutation = useDeleteHiveNoteMutation()

	const [hiveNoteToUpdate, setHiveNoteToUpdate] = useState<HiveNote>()
	const [hiveNoteToDelete, setHiveNoteToDelete] = useState<HiveNote>()

	const [open, { toggleOn: handleOpen, toggleOff: handleClose }] = useToggle(
		false,
	)

	if (isLoading) {
		return <CircularProgress />
	}

	return (
		<>
			<Grid container direction="column" alignItems="stretch" spacing={2}>
				<Grid item container justify="space-between">
					<Grid item>
						<StyledTypography variant="h5">Megjegyzések</StyledTypography>
					</Grid>
					<Grid item>
						<Button variant="contained" color="primary" onClick={handleOpen}>
							Megjegyzés hozzáadása
						</Button>
					</Grid>
				</Grid>
				<Grid item style={{ maxHeight: notesMaxHeight, overflowY: "auto" }}>
					<HiveNoteList
						hiveNotes={(data ?? []).map((hiveNote) => {
							// This transformation will be obsolete after
							// https://www.notion.so/HiveNote-Hive-Note-nevek-egys-ges-t-se-8c6dc92f715040028f3283998b6a7325
							// and
							// https://www.notion.so/Tag-m-k-d-s-egys-ges-t-se-b1139832ad0842ee9088e0734de62ce9
							const { id, flags, attachment, text, createdAt } = hiveNote
							const [firstFlag] = flags as Array<HiveNoteFlag | undefined>
							return {
								key: id.toString(),
								flag: firstFlag?.key,
								imgSrc: attachment ?? undefined,
								text: text ?? undefined,
								onEdit: () => setHiveNoteToUpdate(hiveNote),
								onDelete: () => setHiveNoteToDelete(hiveNote),
								date: parseISO(createdAt),
							}
						})}
					/>
				</Grid>
			</Grid>

			{/* New HiveNote Dialog */}
			<FormDialog
				mutationDialogProps={{
					dialog: {
						open,
						onClose: handleClose,
						components: {
							title: "Új Megjegyzés Hozzáadása",
						},
					},
					result: createHiveNoteMutation,
				}}
				formik={{
					initialValues: {
						createdAt: new Date(),
						text: "",
						flags: [],
						attachment: undefined,
					},
					onSubmit: async (values) => {
						return createHiveNoteMutation.mutateAsync({ ...values, hiveId })
					},
					fields: <HiveNoteFormFields />,
				}}
			/>

			{/* Update HiveNote Dialog  */}
			{hiveNoteToUpdate && (
				<FormDialog
					mutationDialogProps={{
						dialog: {
							open: true,
							onClose: () => {
								setHiveNoteToUpdate(undefined)
							},
							components: {
								title: "Megjegyzés Módosítása",
							},
						},
						result: updateHiveNoteMutation,
					}}
					formik={{
						isInitialValid: false,
						initialValues: {
							createdAt: parseISO(hiveNoteToUpdate.createdAt),
							text: hiveNoteToUpdate.text ?? undefined,
							flags: hiveNoteToUpdate.flags.map((flag) => flag.key),
							attachment: undefined,
						},
						onSubmit: async (values) => {
							return updateHiveNoteMutation.mutateAsync({
								...values,
								hiveId,
								noteId: hiveNoteToUpdate.id,
							})
						},
						fields: (
							<HiveNoteFormFields
								currentImageSource={hiveNoteToUpdate.attachment ?? undefined}
							/>
						),
					}}
				/>
			)}

			{hiveNoteToDelete && (
				<ConfirmationDialog
					mutationDialogProps={{
						dialog: {
							open: true,
							onClose: () => {
								setHiveNoteToDelete(undefined)
							},
							components: {
								title: "Biztosan törlöd a megjegyzést?",
							},
						},
						result: deleteHiveNoteMutation,
					}}
					onConfirm={async () => {
						return deleteHiveNoteMutation.mutateAsync({
							noteId: hiveNoteToDelete.id,
							hiveId: hiveNoteToDelete.hiveId,
						})
					}}
				/>
			)}
		</>
	)
}

export default HiveNoteListContainer
