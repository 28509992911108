import { Grid } from "@material-ui/core"
import React from "react"
import styled, { css } from "styled-components"

type MeasurementsCardAndChartGridProps = {
	measurementsCard: React.ReactNode
	chart: React.ReactNode
}

const StyledGrid = styled(Grid)(
	(props) => css`
		${props.theme.breakpoints.down("sm")} {
			justify-content: center;
		}
	`,
)

const MeasurementsCardAndChartGrid: React.FC<MeasurementsCardAndChartGridProps> = ({
	measurementsCard,
	chart,
}) => {
	return (
		<Grid container direction="row" spacing={2}>
			<StyledGrid item xs={12} sm={4}>
				{measurementsCard}
			</StyledGrid>

			<Grid item xs={12} sm={8}>
				{chart}
			</Grid>
		</Grid>
	)
}

export default MeasurementsCardAndChartGrid
