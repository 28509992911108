import { Button, Divider, Grid, Snackbar, Typography } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import {
	DeviceCommandType,
	useExecuteDeviceCommandMutation,
} from "@space-apps/beebox-api-client"
import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import { useToggle } from "../hooks/useToggle"

type DeviceActionButtonsProps = {
	deviceStatus?: string
	deviceSerial: string
}

const OnButton = styled(Button)(
	(props) => css`
		color: ${props.theme.palette.success.light};
	`,
)

const OffButton = styled(Button)(
	(props) => css`
		color: ${props.theme.palette.error.main};
	`,
)

const SleepButton = styled(Button)(
	(props) => css`
		color: ${props.theme.palette.warning.dark};
	`,
)

const DeviceActionsButtons: React.FC<DeviceActionButtonsProps> = ({
	deviceStatus,
	deviceSerial,
}) => {
	const {
		mutate: sendCommand,
		isSuccess,
		isError,
	} = useExecuteDeviceCommandMutation()
	const [
		openSuccess,
		{ toggleOn: toggleSuccessOn, toggleOff: toggleSuccessOff },
	] = useToggle()
	const [
		openError,
		{ toggleOn: toggleErrorOn, toggleOff: toggleErrorOff },
	] = useToggle()

	const handleSuccessSnackbarClose = (): void => {
		toggleSuccessOff()
	}

	const handleErrorSnackbarClose = (): void => {
		toggleErrorOff()
	}

	useEffect(() => {
		if (isSuccess) {
			toggleSuccessOn()
		}
	}, [isSuccess, toggleSuccessOn])

	useEffect(() => {
		if (isError) {
			toggleErrorOn()
		}
	}, [isError, toggleErrorOn])
	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<Typography variant="h5">Eszköz távoli vezérlése</Typography>
				<Divider />
			</Grid>

			<Grid item>
				<Grid container spacing={2}>
					<Grid item>
						<OnButton
							variant="outlined"
							disabled={deviceStatus === "ON"}
							onClick={() =>
								sendCommand({
									deviceId: deviceSerial,
									commandType: DeviceCommandType.On,
								})
							}
						>
							ON
						</OnButton>
					</Grid>
					<Grid item>
						<OffButton
							variant="outlined"
							disabled={deviceStatus === "OFF"}
							onClick={() =>
								sendCommand({
									deviceId: deviceSerial,
									commandType: DeviceCommandType.Off,
								})
							}
						>
							OFF
						</OffButton>
					</Grid>
					<Grid item>
						<SleepButton
							disabled={deviceStatus === "SLEEP"}
							variant="outlined"
							onClick={() =>
								sendCommand({
									deviceId: deviceSerial,
									commandType: DeviceCommandType.Sleep,
									commandBody: {
										sleepTime: 60,
									},
								})
							}
						>
							Sleep for 1h
						</SleepButton>
					</Grid>
				</Grid>
			</Grid>
			{isSuccess && (
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					onClose={handleSuccessSnackbarClose}
					open={openSuccess}
					autoHideDuration={2000}
				>
					<Alert severity="success" onClose={handleSuccessSnackbarClose}>
						Parancs elküldve!
					</Alert>
				</Snackbar>
			)}
			{isError && (
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					onClose={handleErrorSnackbarClose}
					open={openError}
					autoHideDuration={2000}
				>
					<Alert severity="error" onClose={handleErrorSnackbarClose}>
						Parancs elküldése sikertelen!
					</Alert>
				</Snackbar>
			)}
		</Grid>
	)
}

export default DeviceActionsButtons
