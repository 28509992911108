import type { Measurements } from "@space-apps/beebox-api-client"
import type {
	NumberMeasurementChartDataType,
	RechartSeries,
} from "@space-apps/honeycomb"
import { isEmpty } from "lodash"
import type { DateInterval } from "../context"

export function isDataEmpty(
	data: RechartSeries<NumberMeasurementChartDataType>,
): boolean {
	return data.every((item) => isEmpty(item.data))
}

export function padData(
	data: NumberMeasurementChartDataType[],
	interval: DateInterval,
): NumberMeasurementChartDataType[] {
	return [
		{
			dateInMs: interval.from.getTime(),
			value: null,
		},
		...data,
		{
			dateInMs: interval.to.getTime(),
			value: null,
		},
	]
}

export function convertMeasurementsToData<TMeasurementValue extends number>(
	measurements: Measurements<TMeasurementValue>,
	interval?: DateInterval,
): NumberMeasurementChartDataType[] {
	const data = measurements.map(({ date, value }) => ({
		dateInMs: date.getTime(),
		value,
	}))
	if (interval) {
		return padData(data, interval)
	}
	return data
}

export function convertMeasurementsToSerie<TMeasurementValue extends number>(
	name: string,
	measurements: Measurements<TMeasurementValue>,
	interval?: DateInterval,
): { name: string; data: NumberMeasurementChartDataType[] } {
	return {
		name,
		data: convertMeasurementsToData(measurements, interval),
	}
}
