import {
	Divider,
	Drawer,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core"
import { ExitToApp, Home, Map, Person } from "@material-ui/icons"
import { useLogoutMutation } from "@space-apps/beebox-api-client"
import { BeeBoxLogoIcon } from "@space-apps/honeycomb"
import React from "react"
import styled, { css } from "styled-components"
import WrapperLink from "./WrapperLink"

type DrawerMenuProps = {
	open: boolean
	toggleOpen: () => void
}

const MenuItems = [
	{
		label: "Méhészet",
		to: "/",
		key: 1,
		icon: <Home color="primary" />,
	},
	{
		label: "Térkép",
		to: "/map",
		key: 2,
		icon: <Map color="primary" />,
	},
	{
		label: "Profil",
		to: "/profile",
		key: 3,
		icon: <Person color="primary" />,
	},
]

const HoverWrapper = styled.div(
	(props) => css`
		&:hover {
			background-color: ${props.theme.palette.grey[100]};
		}
	`,
)

const StyledGrid = styled(Grid)(
	(props) => css`
		margin-top: ${props.theme.spacing(2)}px;
		margin-bottom: ${props.theme.spacing(2)}px;
	`,
)

const StyledDiv = styled.div`
	min-width: 250px;
`

const DrawerMenu: React.FC<DrawerMenuProps> = ({ open, toggleOpen }) => {
	const { mutate: logout } = useLogoutMutation()

	return (
		<Drawer anchor="left" open={open} onClose={toggleOpen}>
			<StyledDiv>
				<StyledGrid container justify="center">
					<BeeBoxLogoIcon />
				</StyledGrid>
				<List>
					{MenuItems.map((item) => (
						<WrapperLink key={item.key} to={item.to}>
							<HoverWrapper>
								<ListItem>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText>{item.label}</ListItemText>
								</ListItem>
							</HoverWrapper>
						</WrapperLink>
					))}
				</List>
				<Divider />
				<List>
					<HoverWrapper>
						<ListItem button onClick={() => logout()}>
							<ListItemIcon>
								<ExitToApp color="primary" />
							</ListItemIcon>
							<ListItemText>Kijelentkezés</ListItemText>
						</ListItem>
					</HoverWrapper>
				</List>
			</StyledDiv>
		</Drawer>
	)
}

export default DrawerMenu
