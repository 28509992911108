import { Grid } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Alert } from "@material-ui/lab"
import { useHiveQuery } from "@space-apps/beebox-api-client"
import { isNil } from "lodash"
import React from "react"
import type { RouteComponentProps } from "react-router-dom"
import { Redirect } from "react-router-dom"
import Camera from "../components/Camera"
import sensor from "../utils/sensor"
import PageWithContainer from "./PageWithContainer"

type MatchParams = {
	deviceId: string
	hiveId: string
}
export type CameraPageProps = RouteComponentProps<MatchParams>

const CameraPage: React.FC<CameraPageProps> = ({ match }) => {
	/* const { data: device, isLoading, error } = useDeviceQuery({
		id: Number(match.params.deviceId),
	}) */
	const { data: hive, isLoading: hiveLoading, error: hiveError } = useHiveQuery(
		{
			id: Number(match.params.hiveId),
		},
	)

	if (hiveLoading) {
		return (
			<PageWithContainer>
				<CircularProgress />
			</PageWithContainer>
		)
	}
	if (!hive || hiveError != null) {
		return (
			<PageWithContainer>
				<Grid container justify="center" alignItems="center">
					{hiveError != null && (
						<Alert severity="error" variant="outlined">
							{(hiveError as Error).message}
						</Alert>
					)}
				</Grid>
			</PageWithContainer>
		)
	}
	if (sensor.hasCamera(hive) && !isNil(hive)) {
		return (
			<PageWithContainer
				title={`${hive.name} Kamera (ID: ${hive.devices[0].id})`}
			>
				<Camera hive={hive} />
			</PageWithContainer>
		)
	}
	return <Redirect to="/not-found" />
}

export default CameraPage
