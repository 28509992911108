import React from "react"
import * as yup from "yup"
import { useCreateQueenMutation } from "../hooks/hiveInspectionSheet/useCreateQueen"
import { useUpdateQueenMutation } from "../hooks/hiveInspectionSheet/useUpdateQueenMutation"
import type { HiveInspectionSheetQueen } from "../utils/hiveInspectionSheet"
import FormDialog from "./FormDialog"
import { HiveQueenFields } from "./HiveInspectionSheetFields"

export type HiveQueenFormDialogProps = {
	hiveId: number
	open: boolean
	onClose: () => void
	initialValues?: HiveInspectionSheetQueen
}

const validationSchema = yup.object().shape({
	mark: yup.string().required(),
	number: yup.string().required(),
	swapDate: yup.date(),
	swapReason: yup.string(),
	morphologyColor: yup.string(),
	morphologySucc: yup.string(),
})

const defaultInitialValues: Omit<HiveInspectionSheetQueen, "id"> = {
	number: "",
	mark: "",
	swapDate: new Date(),
	swapReason: undefined,
	morphologyColor: undefined,
	morphologySucc: undefined,
}

export const HiveQueenFormDialog: React.FC<HiveQueenFormDialogProps> = ({
	hiveId,
	open,
	onClose,
	initialValues,
}) => {
	const createQueenMutation = useCreateQueenMutation()
	const updateQueenMutation = useUpdateQueenMutation({ hiveId })
	if (initialValues) {
		return (
			<FormDialog
				formik={{
					initialValues,
					onSubmit: async (values) => {
						return updateQueenMutation.mutateAsync({
							queen: values,
						})
					},
					validateOnChange: true,
					validationSchema,
					isInitialValid: false,
					fields: <HiveQueenFields />,
				}}
				mutationDialogProps={{
					result: updateQueenMutation,
					dialog: {
						open,
						onClose,
						components: {
							title: "Anya szerkesztése",
						},
					},
				}}
			/>
		)
	}
	return (
		<FormDialog
			formik={{
				initialValues: defaultInitialValues,
				onSubmit: async (values) => {
					return createQueenMutation.mutateAsync({
						hiveId,
						queen: values,
					})
				},
				validateOnChange: true,
				validationSchema,
				fields: <HiveQueenFields />,
			}}
			mutationDialogProps={{
				result: createQueenMutation,
				dialog: {
					open,
					onClose,
					components: {
						title: "Anya adatok bevitele",
					},
				},
			}}
		/>
	)
}
