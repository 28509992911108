import { Grid } from "@material-ui/core"
import type {
	DeviceInHive,
	HiveFlag,
	Sensor,
} from "@space-apps/beebox-api-client"
import React from "react"
import styled, { css } from "styled-components"
import HivePictogramContainer from "./HivePictogramContainer"
import WrapperLink from "./WrapperLink"

type MinimumRequiredHiveDataInList = {
	id: number
	name: string
	sensors: Array<Sensor<number>>
	flags: HiveFlag[]
	devices: DeviceInHive[]
}

export type HivePictogramListProps = {
	hives: MinimumRequiredHiveDataInList[]
}

const PictogramGrid = styled(Grid)`
	height: 24rem;
	width: 20rem;

	min-height: 21rem;
	min-width: 18rem;

	&:hover {
		cursor: pointer;
	}
`

const ContainerGrid = styled(Grid)(
	(props) => css`
		${props.theme.breakpoints.down("sm")} {
			justify-content: center;
		}
		${props.theme.breakpoints.up("sm")} {
			justify-content: flex-start;
		}
	`,
)

const HivePictogramList: React.FC<HivePictogramListProps> = ({ hives }) => {
	return (
		<ContainerGrid container spacing={2}>
			{hives
				.sort((hive1, hive2) => (hive1.id > hive2.id ? 1 : -1))
				.map((item) => (
					<Grid
						key={item.id}
						item
						container
						justify="center"
						xs={12}
						sm={6}
						md={4}
						lg={3}
					>
						<PictogramGrid>
							<WrapperLink to={`/hive/${item.id}`}>
								<HivePictogramContainer hive={item} />
							</WrapperLink>
						</PictogramGrid>
					</Grid>
				))}
		</ContainerGrid>
	)
}

export default HivePictogramList
