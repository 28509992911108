/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as hiveInspectionSheet from "../../utils/hiveInspectionSheet"
import { useSetHiveInspectionSheetWrapperMutation } from "./useSetHiveInspectionSheetWrapperMutation"

export function useCreateHiveInspectionSheetEntryMutation({
	hiveId,
}: {
	hiveId: number
}) {
	return useSetHiveInspectionSheetWrapperMutation(
		hiveId,
		hiveInspectionSheet.createHiveInspectionSheetEntry,
	)
}
