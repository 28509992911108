import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import ProtectedRoute from "./components/ProtectedRoute"
import CameraPage from "./pages/CameraPage"
import DeviceDebugListPage from "./pages/DeviceDebugListPage"
import DeviceDebugPage from "./pages/DeviceDebugPage"
import HiveGroupListPage from "./pages/HiveGroupListPage"
import HiveGroupPage from "./pages/HiveGroupPage"
import HiveInspectionSheetPage from "./pages/HiveInspectionSheetPage"
import HivePage from "./pages/HivePage"
import LoginPage from "./pages/LoginPage"
import MapPage from "./pages/MapPage"
import ProfilePage from "./pages/ProfilePage"

const Routes: React.FC = () => {
	return (
		<Switch>
			<ProtectedRoute path="/login">
				<LoginPage />
			</ProtectedRoute>

			{/* 
					Should be palced before the protected route, so logged
			 		users can access the 404 page 
			*/}
			<Route path="/not-found">
				<Grid
					container
					justify="center"
					alignItems="center"
					style={{ height: "100%" }}
				>
					<Grid item container alignItems="center" direction="column">
						<Typography variant="h1">404</Typography>
						<Typography variant="h2">Not Found</Typography>
					</Grid>
				</Grid>
			</Route>

			<ProtectedRoute guard path="/">
				<Switch>
					<Route exact path="/">
						<HiveGroupListPage />
					</Route>

					<Route path="/map" component={MapPage} />

					<Route path="/hive/:hiveId" exact component={HivePage} />
					<Route path="/hive/:hiveId/camera" component={CameraPage} />
					<Route
						path="/hive/:hiveId/inspection-sheet"
						component={HiveInspectionSheetPage}
					/>

					<Route path="/hivegroup/:hiveGroupId" component={HiveGroupPage} />

					<Route path="/profile" component={ProfilePage} />

					<Route path="/debug/hive/:hiveId" component={DeviceDebugListPage} />
					<Route path="/debug/device/:deviceId" component={DeviceDebugPage} />

					<Redirect to="/not-found" />
				</Switch>
			</ProtectedRoute>

			<Redirect to="/not-found" />
		</Switch>
	)
}

export default Routes
