import { CircularProgress, Grid } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import type { SensorMeasurementsSeriesVariables } from "@space-apps/beebox-api-client"
import { useSensorMeasurementsSeriesQuery } from "@space-apps/beebox-api-client"
import {
	MassChart,
	NumberMeasurementChart,
	TemperatureChart,
} from "@space-apps/honeycomb"
import { isEmpty } from "lodash"
import React from "react"
import styled, { css } from "styled-components"
import { useIntervalContext } from "../context"
import { convertMeasurementsToSerie } from "../utils/chart"

export type HiveChartsProps = {
	deviceId: number
	className?: string
}

const ChartDiv = styled.div(
	() => css`
		height: 15rem;
	`,
)

const ChartWithLegendsDiv = styled.div(
	() => css`
		height: 18rem;
	`,
)

const HiveCharts: React.FC<HiveChartsProps> = ({ deviceId, className }) => {
	const [interval] = useIntervalContext()

	function variables(sensorKey: string): SensorMeasurementsSeriesVariables {
		return {
			...interval,
			deviceId,
			sensorKey,
		}
	}
	const {
		isLoading: isWeightLoading,
		data: weightMeasurements,
	} = useSensorMeasurementsSeriesQuery<number>(variables("weight"))
	const {
		isLoading: isTemperatureLoading,
		data: temperatureMeasurements,
	} = useSensorMeasurementsSeriesQuery<number>(variables("temp_load"))

	const {
		isLoading: isNetworkSignalLoading,
		data: networkSignalMeasurements,
	} = useSensorMeasurementsSeriesQuery<number>(variables("network_signal"))

	const {
		isLoading: isBatteryLoading,
		data: batteryMeasurements,
	} = useSensorMeasurementsSeriesQuery<number>(variables("battery"))
	// const humidity = useSensorMeasurementsSeriesQuery(variables("humidity"))

	const isLoading =
		isWeightLoading ||
		isTemperatureLoading ||
		isBatteryLoading ||
		isNetworkSignalLoading

	if (isLoading) {
		return <CircularProgress />
	}

	if (
		!weightMeasurements ||
		!temperatureMeasurements ||
		isEmpty(weightMeasurements) ||
		isEmpty(temperatureMeasurements)
	) {
		return (
			<Alert severity="warning" variant="outlined">
				Nem található adat a kiválasztott időszakhoz
			</Alert>
		)
	}

	const weightData = convertMeasurementsToSerie(
		"Tömeg",
		weightMeasurements,
		interval,
	)
	const temperatureData = convertMeasurementsToSerie(
		"Hőmérséklet",
		temperatureMeasurements,
		interval,
	)

	const networkData = networkSignalMeasurements
		? convertMeasurementsToSerie(
				"Hálózat jelerősség",
				networkSignalMeasurements,
				interval,
		  )
		: undefined

	const batteryData = batteryMeasurements
		? convertMeasurementsToSerie(
				"Akku töltöttség",
				batteryMeasurements,
				interval,
		  )
		: undefined

	return (
		<Grid container className={className} direction="column" spacing={6}>
			<Grid item xs={12}>
				<ChartDiv>
					<MassChart key={Math.random()} data={[weightData]} />
				</ChartDiv>
			</Grid>
			<Grid item xs={12}>
				<ChartDiv>
					<TemperatureChart key={Math.random()} data={[temperatureData]} />
				</ChartDiv>
			</Grid>
			{/* <Grid item xs={12}>
								<ChartDiv>
									<HumidityChart
										data={data}
																				quantities={quantities}
									/>
								</ChartDiv>
					</Grid>
				*/}
			{networkData && batteryData && (
				<Grid item xs={12}>
					<ChartWithLegendsDiv>
						<NumberMeasurementChart
							dot
							data={[networkData, { ...batteryData, yAxisId: "right" }]}
							displayLegends
							biAxial
						/>
					</ChartWithLegendsDiv>
				</Grid>
			)}
		</Grid>
	)
}

export default HiveCharts
