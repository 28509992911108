import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	MenuItem,
	Radio as RadioButton,
	RadioGroup,
	TextField,
} from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers"
import type { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import type { FieldProps } from "formik"
import { Field } from "formik"
import React from "react"

type RadioFieldProps = FieldProps<string> & {
	options: React.ReactText[]
	label: string
}

type CheckboxFieldProps = FieldProps<boolean> & {
	label: string
}

type SelectFieldProps = FieldProps<string> & {
	options: React.ReactText[]
	label: string
}

export const RadioField: React.FC<RadioFieldProps> = ({
	field,
	form,
	options,
	label,
}) => {
	return (
		<FormControl component="fieldset" margin="normal" fullWidth>
			<FormLabel component="legend">{label}</FormLabel>
			<RadioGroup
				row
				name={field.name}
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				value={field.value}
				onChange={(e) => form.setFieldValue(field.name, Number(e.target.value))}
			>
				{options.map((option) => (
					<FormControlLabel
						key={option}
						value={option}
						control={<RadioButton />}
						label={option}
					/>
				))}
			</RadioGroup>
		</FormControl>
	)
}

export const SelectField: React.FC<SelectFieldProps> = ({
	field,
	form,
	options,
	label,
	...props
}) => {
	return (
		<TextField
			select
			value={field.value}
			onChange={(e) => form.setFieldValue(field.name, e.target.value)}
			label={label}
			variant="outlined"
			{...props}
		>
			{options.map((item) => (
				<MenuItem key={item} value={item}>
					{item}
				</MenuItem>
			))}
		</TextField>
	)
}

export const FormikTextField = ({
	field,
	form,
	...props
}: FieldProps): React.ReactElement => {
	return (
		<TextField
			error={
				form.touched[field.name] !== undefined &&
				Boolean(form.errors[field.name])
			}
			helperText={
				form.touched[field.name] !== undefined && form.errors[field.name]
			}
			{...field}
			{...props}
		/>
	)
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
	field,
	form,
	label,
	...props
}): React.ReactElement => {
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={field.value}
					onChange={(e) => {
						form.setFieldValue(field.name, Boolean(e.target.checked))
					}}
					{...props}
				/>
			}
			label={label}
		/>
	)
}

export const FormikDatePicker: React.FC<FieldProps & { disabled: boolean }> = ({
	field,
	form,
	...props
}): React.ReactElement => {
	return (
		<DatePicker
			{...field}
			{...props}
			onChange={(date: MaterialUiPickersDate) => {
				if (date) {
					form.setFieldValue(field.name, date)
				}
			}}
			error={
				form.touched[field.name] !== undefined &&
				Boolean(form.errors[field.name])
			}
			helperText={
				form.touched[field.name] !== undefined && form.errors[field.name]
			}
		/>
	)
}

export const HiveQueenFields: React.FC = () => {
	return (
		<>
			<Field
				component={FormikTextField}
				name="number"
				variant="outlined"
				label="Törzskönyvi száma"
				margin="normal"
				required
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="mark"
				variant="outlined"
				label="Jele"
				margin="normal"
				required
				fullWidth
			/>

			<Field
				component={FormikDatePicker}
				name="swapDate"
				required
				inputVariant="outlined"
				label="Csere / hozzáadás dátuma"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="swapReason"
				multiline
				variant="outlined"
				label="Anyacsere oka"
				margin="normal"
				fullWidth
			/>
			<Field
				component={FormikTextField}
				name="morphologyColor"
				variant="outlined"
				margin="normal"
				label="Morfológia - Szín"
				fullWidth
			/>
			<Field
				component={FormikTextField}
				name="morphologySucc"
				variant="outlined"
				margin="normal"
				label="Morfológia - Szipóka"
				fullWidth
			/>
		</>
	)
}

export const HiveInspectionSheetFields: React.FC = () => {
	return (
		<>
			<Field
				component={FormikDatePicker}
				name="date"
				required
				inputVariant="outlined"
				label="Dátum"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="population"
				type="number"
				required
				label="Nép"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="comb"
				type="number"
				required
				label="Lép"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="open"
				type="number"
				required
				label="Nyit"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="fed"
				type="number"
				required
				label="Fed"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="food"
				type="number"
				required
				label="Élelem"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={RadioField}
				name="ab"
				options={[1, 2, 3, 4]}
				required
				label="AB"
			/>

			<Field
				component={RadioField}
				name="calm"
				options={[1, 2, 3, 4, 5]}
				required
				label="Nyugodt"
			/>

			<Field
				component={RadioField}
				name="stay"
				options={[1, 2, 3]}
				required
				label="Lépen maradás"
			/>

			<Field
				component={RadioField}
				name="clean"
				options={[1, 2, 3]}
				required
				label="Tisztogató hajlam"
			/>

			<Field
				component={RadioField}
				name="swarm"
				options={[1, 2, 3, 4, 5]}
				required
				label="Rajzási hajlam"
			/>

			<Field
				component={RadioField}
				name="empty"
				options={[1, 2, 3, 4, 5]}
				required
				label="Üres"
			/>

			<Field
				component={FormikTextField}
				name="fakecomb"
				type="number"
				required
				label="Műlép"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="bee"
				type="number"
				required
				label="Méh"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="fias"
				type="number"
				required
				label="Fias"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="honey"
				type="number"
				required
				label="Méz"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="sugar"
				type="number"
				required
				label="Cukor (kg)"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={SelectField}
				name="type"
				required
				label="Kaptár típusa"
				variant="outlined"
				options={["Rakodó", "Fekvő"]}
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="size"
				type="number"
				rows={2}
				rowsMax={4}
				label="Méret"
				variant="outlined"
				margin="normal"
				fullWidth
			/>

			<Field
				component={FormikTextField}
				name="comment"
				multiline
				rows={2}
				rowsMax={4}
				label="Megjegyzés"
				variant="outlined"
				margin="normal"
				fullWidth
			/>
		</>
	)
}
