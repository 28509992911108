import Paper from "@material-ui/core/Paper"
import TableCell from "@material-ui/core/TableCell"
import type { Sensor } from "@space-apps/beebox-api-client"
import { compareDesc, parseISO } from "date-fns"
import { isNil } from "lodash"
import React from "react"
import type { TableCellRenderer, TableHeaderProps } from "react-virtualized"
import { AutoSizer, Column, Table } from "react-virtualized"
import styled from "styled-components"
import format from "../utils/format"
import type { MeasurementWithSensorKeyArray } from "./SensorDebug"

const StyledTableCell = styled(TableCell)`
	display: flex;
	alignitems: center;
	boxsizing: border-box;
	flex: 1;
`

interface SensorDebugTableProps {
	measurements: MeasurementWithSensorKeyArray
	sensors: Sensor[]
}

interface ColumnData {
	dataKey: string
	label: string
	numeric?: boolean
	width: number
}

interface Row {
	index: number
}

interface MuiVirtualizedTableProps {
	columns: ColumnData[]
	headerHeight?: number
	onRowClick?: () => void
	rowCount: number
	rowGetter: (row: Row) => Data
	rowHeight?: number
}

const VirtualizedTable: React.FC<MuiVirtualizedTableProps> = (props) => {
	const cellRenderer: TableCellRenderer = ({
		cellData,
	}): React.ReactElement => {
		const { rowHeight } = props
		return (
			<StyledTableCell
				component="div"
				variant="body"
				style={{ height: rowHeight }}
				align="center"
			>
				{cellData}
			</StyledTableCell>
		)
	}

	const headerRenderer = ({
		label,
	}: TableHeaderProps & { columnIndex: number }): React.ReactElement => {
		const { headerHeight } = props

		return (
			<TableCell
				component="div"
				variant="head"
				style={{ height: headerHeight }}
				align="center"
			>
				<span>{label}</span>
			</TableCell>
		)
	}

	const { columns, rowHeight, headerHeight, ...tableProps } = props
	return (
		<AutoSizer>
			{({ height, width }) => (
				<Table
					height={height}
					width={width}
					rowHeight={rowHeight ?? 24}
					gridStyle={{
						direction: "inherit",
					}}
					rowStyle={{
						display: "flex",
						alignItems: "center",
						boxSizing: "border-box",
					}}
					headerHeight={headerHeight ?? 50}
					{...tableProps}
				>
					{columns.map(({ dataKey, ...other }, index) => {
						return (
							<Column
								key={dataKey}
								headerRenderer={(headerProps) =>
									headerRenderer({
										...headerProps,
										columnIndex: index,
									})
								}
								cellRenderer={cellRenderer}
								dataKey={dataKey}
								flexGrow={1}
								{...other}
							/>
						)
					})}
				</Table>
			)}
		</AutoSizer>
	)
}

// ---

type Data = {
	[x: string]: number | string
}

export const TABLE_SENSOR_KEYS = [
	"weight",
	"network_signal",
	"temp_load",
	"postime",
	"gsm",
	"battery",
	"wifi",
	"coords",
]

function createRows(measurements: MeasurementWithSensorKeyArray): Data[] {
	const tableRows = measurements.reduce<
		Record<
			string,
			{
				[x: string]: number | string
			}
		>
	>((acc, { key, measurements: currentMeasurements }) => {
		currentMeasurements.forEach(({ date, value }) => {
			if (TABLE_SENSOR_KEYS.includes(key)) {
				const dateStr = format.dateTime(date)
				if (!isNil(acc[dateStr])) {
					acc[dateStr][key] = value as number
				} else {
					acc[dateStr] = { [key]: value as number }
				}
			}
		})
		return acc
	}, {})

	const rows = Object.entries(tableRows).map(([date, sensorValues]) => ({
		date,
		...sensorValues,
	}))

	return rows.sort((a, b) => compareDesc(parseISO(a.date), parseISO(b.date)))
}

function createHeader(sensors: Sensor[]): ColumnData[] {
	const tableHeader = TABLE_SENSOR_KEYS.reduce<ColumnData[]>((acc, key) => {
		const sensor = sensors.find((s) => s.key === key)
		if (sensor) {
			acc.push({
				width: 150,
				label: `${sensor.name} (${sensor.dataType.display.unit})`,
				dataKey: sensor.key,
			})
		}
		return acc
	}, [])
	return tableHeader
}

const SensorDebugTable: React.FC<SensorDebugTableProps> = ({
	sensors,
	measurements,
}) => {
	const msRows = createRows(measurements)
	const header = createHeader(sensors)
	return (
		<Paper style={{ height: 400, width: "100%" }}>
			<VirtualizedTable
				rowCount={Object.entries(msRows).length}
				rowGetter={({ index }) => msRows[index]}
				columns={[{ width: 200, label: "Dátum", dataKey: "date" }, ...header]}
			/>
		</Paper>
	)
}

export default SensorDebugTable
